import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Axios from 'axios'
import GlobalContext from '../contexts/GlobalContext'

const DeleteTenantPopup = ({
  handleClosePopUp,
  tenant,
  openPopup,
  setOpenPopup,
}) => {
  const { loadingState, setLoadingState } = useContext(GlobalContext)
  const { tenantFriendlyName, clientTenantId } = tenant

  const deleteTenant = async () => {
    setLoadingState(true)

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/delete-tenant`,
        {
          clientTenantId,
        }
      )

      setLoadingState(false)

      if (response.status === 200) {
        setOpenPopup(false)
        window.location.reload()
      }
    } catch (error) {
      toast.error('Error deleting tenant. Please try again later')
      console.error(error.response.data)
    }
  }

  return (
    <div className='popup-bg'>
      <div className='popup-container delete-tenant-popup'>
        <h3 className='text-center mb-4'>Confirm Deletion</h3>
        {loadingState === true ? (
          <div>
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
            <p>Deleting tenant...</p>
          </div>
        ) : (
          <>
            <p className='ml-4'>
              Are you sure you'd like to delete{' '}
              <strong>{tenantFriendlyName}</strong>?
            </p>
            <br />
            <p className='ml-4'>
              This will remove the tenant from the Inforcer platform, however
              the Application Registration will need to removed manually.
            </p>
            <div className='footer mt-[50px] mb-2'>
              <button
                className='btn navy-btn ml-4 mr-2 text-white'
                onClick={handleClosePopUp}
              >
                Back
              </button>
              <button
                className='btn bg-red-800 text-white'
                onClick={deleteTenant}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DeleteTenantPopup
