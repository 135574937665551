import { useReducer, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { Dialog } from '@headlessui/react'
import ShowHideToggle from '../FormShowHideToggle/ShowHideToggle'
import EmailInput from './EmailInput'
import Modal from '../Modal/Modal'
import Alerts from '../alerts/Alerts'
import ModalActionButtons from '../Modal/ModalActionButtons'

const stateKeyTo = 'newMailTo'
const stateKeyCc = 'newMailCc'
const stateKeyBcc = 'newMailBcc'
const defaultTrackAllChangesState = {
  [stateKeyTo]: [],
  [stateKeyCc]: [],
  [stateKeyBcc]: [],
}

const trackAllChangesReducer = (state, action) => {
  switch (action.target) {
    case stateKeyTo:
      return {
        ...state,
        [stateKeyTo]: action.payload,
      }
    case stateKeyCc:
      return {
        ...state,
        [stateKeyCc]: action.payload,
      }
    case stateKeyBcc:
      return {
        ...state,
        [stateKeyBcc]: action.payload,
      }
    case 'reset':
      return defaultTrackAllChangesState
    default:
      return state
  }
}

const TrackChangesEmailInputs = () => {
  const [fieldValidationErr, setFieldValidationErr] = useState('')
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [trackChangesRecipients, dispatchTrackAllChanges] = useReducer(
    trackAllChangesReducer,
    defaultTrackAllChangesState
  )
  // Access the client
  const queryClient = useQueryClient()

  const trackChangesForAllTenants = useMutation({
    mutationFn: () =>
      Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/setTrackChangesRecipientsAllTenants`,
        trackChangesRecipients
      ),
    onSuccess: () => {
      // set success message
      dispatchTrackAllChanges({ target: 'reset' })
    },
  })

  return (
    <ShowHideToggle
      titleHtml={
        <h4>Set Recipients for Track Changes Notifications on All Tenants</h4>
      }
    >
      <div className='flex flex-col pb-1 gap-3'>
        <Alerts title='Please note' alert='info' margin='mb-5 mt-3'>
          <p>
            Currently we only support a single recipient for each field below.
            The "To" field cannot be left blank and all recipients must be
            specified at the same time as leaving a field blank will remove them
            from the recipients list. You cannot set "to", "cc", and "bcc" to
            the same email address.
          </p>
        </Alerts>

        <EmailInput
          placeholder='To:'
          values={trackChangesRecipients[stateKeyTo]}
          onTagChange={newToEmails =>
            dispatchTrackAllChanges({
              target: stateKeyTo,
              payload: newToEmails,
            })
          }
        />
        <EmailInput
          placeholder='Cc:'
          values={trackChangesRecipients[stateKeyCc]}
          onTagChange={newCcEmails =>
            dispatchTrackAllChanges({
              target: stateKeyCc,
              payload: newCcEmails,
            })
          }
        />
        <EmailInput
          placeholder='Bcc:'
          values={trackChangesRecipients[stateKeyBcc]}
          onTagChange={newBccEmails =>
            dispatchTrackAllChanges({
              target: stateKeyBcc,
              payload: newBccEmails,
            })
          }
        />
      </div>
      <div className='flex gap-3'>
        <button
          type='button'
          className='btn navy-btn w-fit'
          onClick={() => {
            dispatchTrackAllChanges({ target: 'reset' })
          }}
        >
          <FontAwesomeIcon icon={faTrash} className='pr-2' />
          Clear All
        </button>
        <button
          type='button'
          className='btn cyan-btn w-fit'
          onClick={() => {
            if (trackChangesRecipients[stateKeyTo].length === 0)
              setFieldValidationErr('"To" Field cannot be blank')
            else if (
              trackChangesRecipients[stateKeyTo].some(
                email =>
                  trackChangesRecipients[stateKeyCc].includes(email) ||
                  trackChangesRecipients[stateKeyBcc].includes(email)
              )
            )
              setFieldValidationErr(
                'You cannot use the same email address for all fields'
              )
            else {
              setFieldValidationErr('')
              setShowConfirmationPopup(true)
            }
          }}
        >
          Submit
        </button>
      </div>
      {fieldValidationErr && (
        <h4 className='font-semibold text-red-600'>{fieldValidationErr}</h4>
      )}
      {trackChangesForAllTenants.error && (
        <h4 className='font-semibold text-red-600'>
          {trackChangesForAllTenants.error}
        </h4>
      )}
      <Modal
        isOpen={showConfirmationPopup}
        setModalOpen={setShowConfirmationPopup}
      >
        <Dialog.Title
          as='h3'
          className='text-lg font-semibold leading-6 text-gray-900'
        >
          Are you sure?
        </Dialog.Title>
        <div className='my-6'>
          <p className='text-md text-gray-500'>
            This will apply these changes to all tenants
          </p>
        </div>
        <ModalActionButtons
          onConfirm={() => {
            trackChangesForAllTenants.mutate()
            setShowConfirmationPopup(false)
          }}
          onCancel={() => setShowConfirmationPopup(false)}
        />
      </Modal>
    </ShowHideToggle>
  )
}

export default TrackChangesEmailInputs
