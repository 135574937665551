import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import DeleteTenantPopup from './DeleteTenantPopup'

const ManageTenantsTable = ({
  targetTenants,
  targetTenantQuery,
  setTargetTenantQuery,
}) => {
  const navigate = useNavigate()

  const [openPopup, setOpenPopup] = useState(false)
  const [currentTenant, setCurrentTenant] = useState({
    tenantFriendlyName: '',
    clientTenantId: '',
  })

  const openOnboarding = () => {
    navigate('/onboarding')
  }

  const clearSearch = () => {
    setTargetTenantQuery('')
  }

  const handleOpenPopUp = (tenantFriendlyName, clientTenantId) => {
    setCurrentTenant({ tenantFriendlyName, clientTenantId })
    setOpenPopup(true)
  }
  const handleClosePopUp = () => {
    setOpenPopup(false)
  }

  // sort Golden tenants to top
  const sortedTenants = [...targetTenants].sort(
    (a, b) => b.isGoldenTenant - a.isGoldenTenant
  )

  // partner center tenants only
  const partnerCenterClientIds = targetTenants
    .filter(e => e.partnerCenter === true)
    .map(partner => partner.clientTenantId)

  return (
    <div>
      <div className='flex items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mb-[20px] mt-[20px]'
          type='text'
          placeholder='Search'
          value={targetTenantQuery}
          onChange={e => setTargetTenantQuery(e.target.value)}
        />
        <button
          type='button'
          className='btn navy-btn clear-btn mr-4'
          onClick={clearSearch}
        >
          Clear
        </button>
        <button
          type='button'
          className='btn cyan-btn flex items-center mr-2'
          onClick={openOnboarding}
        >
          <FontAwesomeIcon icon={icon({ name: 'user-plus' })} />
          &nbsp;Add Tenants
        </button>
        <button
          type='button'
          className='btn slate-btn mr-3 cyan-btn flex items-center'
          onClick={() =>
            navigate(`/tenant-refresh?clientTenantId=${partnerCenterClientIds}`)
          }
        >
          <FontAwesomeIcon className='pr-2' icon={icon({ name: 'rotate' })} />
          Refresh Partner Center
        </button>
      </div>
      <div className='table-container'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td className='tags-th'>Tags</td>
              <td className='tenant-name-th' colSpan={2}>
                Tenant Name
              </td>
            </tr>
            {sortedTenants
              .filter(tenant => tenant && tenant.tenantFriendlyName)
              .map(tenant => (
                <tr
                  key={tenant.msTenantId}
                  // onClick={targetTenantClick}
                  className='tenant-row cursor-pointer'
                >
                  <td className='baseline-cell'>
                    {tenant.backupEnabled === 1 && (
                      <span className='backedup-tag mr-1'>Backed Up</span>
                    )}
                    {tenant.isGoldenTenant && (
                      <span className='baseline-tag'>Baseline</span>
                    )}
                    {tenant.partnerCenter && (
                      <span className='partner-center-tag'>Partner Center</span>
                    )}
                  </td>
                  <td className='tenant-name'>{tenant.tenantFriendlyName}</td>
                  <td className='check-cell right'>
                    <button
                      type='button'
                      onClick={() => {
                        navigate(
                          `/tenant-refresh?clientTenantId=${tenant.clientTenantId}`
                        )
                      }}
                      className='btn slate-btn mr-3'
                    >
                      <FontAwesomeIcon icon={icon({ name: 'rotate' })} />{' '}
                      Refresh
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        navigate(`/edit-tenant/${tenant.clientTenantId}`)
                      }}
                      className='btn cyan-btn mr-3'
                    >
                      <FontAwesomeIcon icon={icon({ name: 'pen-to-square' })} />{' '}
                      Edit
                    </button>
                    <button
                      type='button'
                      className='btn navy-btn'
                      onClick={() =>
                        handleOpenPopUp(
                          tenant.tenantFriendlyName,
                          tenant.clientTenantId
                        )
                      }
                    >
                      <FontAwesomeIcon icon={icon({ name: 'trash' })} /> Delete
                    </button>
                  </td>
                </tr>
              ))}

            {targetTenants?.length === 0 && (
              <tr>
                <td>No tenants found</td>
                <td aria-label='Check Cell' />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {openPopup === true && (
        <DeleteTenantPopup
          handleClosePopUp={handleClosePopUp}
          tenant={currentTenant}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      )}
    </div>
  )
}

const tenantShape = shape({
  msTenantId: string,
  tenantFriendlyName: string,
  backupEnabled: number,
  isGoldenTenant: bool,
  partnerCenter: bool,
  clientTenantId: number,
})

ManageTenantsTable.propTypes = {
  targetTenants: arrayOf(tenantShape).isRequired,
  targetTenantQuery: string.isRequired,
  setTargetTenantQuery: func.isRequired,
}

export default ManageTenantsTable
