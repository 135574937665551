import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Link } from 'react-router-dom'

const AdminPortal = () => (
  <div className='welcome-page admin-home md:container mx-auto'>
    <div className='welcome-panel overflow-hidden shadow-sm'>
      <h2>Admin Portal</h2>
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
      <Link to='/admin-portal/new-client-new-onboarding-code'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'user-plus' })} />
              </span>
              <p className='mt-[18px] font-bold'>
                Create new client and onboarding code
              </p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/add-user'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'user-plus' })} />
              </span>
              <p className='mt-[18px] font-bold'>Add user</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/add-onboarding-code'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'qrcode' })} />
              </span>
              <p className='mt-[18px] font-bold'>Add onboarding code</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/all-clients'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'user-group' })} />
              </span>
              <p className='mt-[18px] font-bold'>View all clients</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/reset-user-pass'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'user-pen' })} />
              </span>
              <p className='mt-[18px] font-bold'>Reset user pass</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/remove-onboarding-code'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'hammer' })} />
              </span>
              <p className='mt-[18px] font-bold'>Remove onboarding code</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/all-tenants'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'users' })} />
              </span>
              <p className='mt-[18px] font-bold'>View all tenants</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/remove-user'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'user-slash' })} />
              </span>
              <p className='mt-[18px] font-bold'>Remove user</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/pause-client'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'pause' })} />
              </span>
              <p className='mt-[18px] font-bold'>Pause Client</p>
            </div>
          </div>
        </div>
      </Link>

      <Link to='/admin-portal/shared-baselines-manage'>
        <div className='feature-card w-full rounded-xl overflow-hidden shadow-sm bg-white p-5'>
          <div className='px-2'>
            <div className='mb-1'>
              <span className='rounded-full icon-bg h-10 p-3 mr-3 flex'>
                <FontAwesomeIcon icon={icon({ name: 'book' })} />
              </span>
              <p className='mt-[18px] font-bold'>Manage Shared Baselines</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  </div>
)

export default AdminPortal
