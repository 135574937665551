export const defaultCellStyle = {
  borderLeft: 'none',
  borderRight: 'none',
}

export const defaultTableProperties = {
  enableStickyHeader: true,
  enableColumnOrdering: false,
  enableColumnPinning: false,
  enableColumnActions: false,
  enableColumnFilters: true,
  enableGlobalFilter: true,
  enableFullScreenToggle: false,
  enableHiding: false,
  enableDensityToggle: false,
  enableFacetedValues: true,
  positionToolbarAlertBanner: 'none',
  initialState: {
    showGlobalFilter: true,
  },
  muiSelectCheckboxProps: {
    color: 'primary',
  },
  muiTableContainerProps: {
    sx: { maxHeight: '100%' },
  },
  muiToolbarAlertBannerProps: {
    sx: {
      marginBottom: '16px',
    },
  },
  muiTableHeadCellProps: {
    sx: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  muiTableBodyCellProps: {
    sx: {
      fontFamily: 'inherit',
      color: 'rgba(23, 27, 58, 0.85)',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  muiTableFooterCellProps: {
    sx: {
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  muiTablePaperProps: {
    sx: {
      boxShadow: 'none',
    },
  },
  muiTableFooterProps: {
    sx: {
      boxShadow: 'none',
    },
  },
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: 'Settings',
      size: 100,
    },
  },
  positionGlobalFilter: 'left',
}

export default { defaultCellStyle, tableInstance: defaultTableProperties }
