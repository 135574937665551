import { arrayOf, func, number, object, shape, string } from 'prop-types'
import ConditionalSettings from './ConditionalSettings'
import HorizontalDivider from '../HorizontalDivider'
import { isTenantLevelSettingPolicyType } from '../../utils/Policy'
import Toggle from '../Toggle/Toggle'
import RadioGroup from './RadioGroup'

const AdditionalDeploymentOptions = ({
  onChange,
  additionalSettingsConfig,
  onNestedFlagChange,
}) => {
  const selectedPoliciesHaveNoAdditionalSettings =
    additionalSettingsConfig.every(setting =>
      isTenantLevelSettingPolicyType(setting.policyId)
    )

  return (
    <div className='additional-options flex flex-col gap-1'>
      {selectedPoliciesHaveNoAdditionalSettings ? (
        <h3 className='py-2 text-xl'>
          There are no deployment options for the selected policies
        </h3>
      ) : (
        additionalSettingsConfig.map(
          ({ policyId, policyHeading, additionalSettings }) => (
            <div key={`${policyId}-${policyHeading}`} className='mb-2'>
              <HorizontalDivider />
              <h3 className='text-lg pb-2'>{policyHeading}</h3>
              {isTenantLevelSettingPolicyType(policyId) ? (
                <p className='py-2 font-semibold opacity-75'>
                  There are no deployment options for this policy type
                </p>
              ) : (
                additionalSettings.map(
                  ({
                    text,
                    flagName,
                    value,
                    renderDropdownIfTrue,
                    renderExchangeOptions,
                    radioOptions,
                    hideToggle,
                  }) => (
                    <div
                      className='flex flex-col pb-3'
                      key={`${policyId}-${flagName}`}
                    >
                      {!hideToggle && (
                        <Toggle
                          checked={value}
                          onChange={() => onChange(policyId, flagName, !value)}
                          labelText={text}
                        />
                      )}

                      {renderDropdownIfTrue && value && (
                        <ConditionalSettings
                          onNestedFlagChange={onNestedFlagChange}
                          optionsToRender={renderDropdownIfTrue}
                          policyId={policyId}
                          parentFlag={flagName}
                        />
                      )}

                      {radioOptions && (
                        <>
                          <div className='font-medium text-base text-gray-900 dark:text-gray-300'>
                            {text}
                          </div>
                          <RadioGroup
                            policyTypeId={policyId}
                            onChange={newValue =>
                              onChange(policyId, flagName, newValue)
                            }
                            options={radioOptions}
                            selected={value}
                          />
                        </>
                      )}

                      {renderExchangeOptions && value && (
                        <div className='flex flex-col gap-1 pl-4 py-3 border-l-2'>
                          {renderExchangeOptions.map(option => (
                            <Toggle
                              key={`${policyId}-${option.flagName}`}
                              checked={option.value}
                              onChange={() =>
                                onNestedFlagChange(
                                  policyId,
                                  flagName,
                                  option.flagName,
                                  !option.value
                                )
                              }
                              labelText={option.text}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  )
                )
              )}
            </div>
          )
        )
      )}
    </div>
  )
}

AdditionalDeploymentOptions.propTypes = {
  onChange: func.isRequired,
  additionalSettingsConfig: arrayOf(
    shape({
      policyId: number.isRequired,
      policyHeading: string.isRequired,
      // not worth defining the shape of nested additional settings for now
      // eslint-disable-next-line react/forbid-prop-types
      additionalSettings: arrayOf(object),
    })
  ).isRequired,
  onNestedFlagChange: func.isRequired,
}
export default AdditionalDeploymentOptions
