import { useCallback, useEffect, useReducer, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { isWebUri } from 'valid-url'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import SSODomainsInput from './SSODomainsInput'
import SSOCheckbox from './SSOCheckbox'
import Alerts from '../alerts/Alerts'
import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'
import SSOFileInput from './SSOFileInput'
import './SSOAdmin.css'
import useGetSSOConfig from './useGetSSOConfig'
import useDeleteSSOConfig from './useDeleteSSOConfig'
import useSubmitSSOConfig from './useSubmitSSOConfig'
import HorizontalDivider from '../HorizontalDivider'

const initialErrorState = {
  entrypoint: [],
  issuer: [],
  publicKey: [],
  domains: [],
}

const errorStateReducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return initialErrorState
    case 'CLEAR_FIELD':
      return { ...state, [action.field]: [] }
    case 'SET_FIELD':
      return { ...state, [action.field]: action.payload }
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: [...state[action.field], action.payload],
      }
    case 'SET_ALL':
      return action.payload
    default:
      return state
  }
}

const SSOAdmin = () => {
  const [loadError, setLoadError] = useState(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [autoProvisionUsers, setAutoProvisionUsers] = useState(false)
  const [publicKey, setPublicKey] = useState('')
  const [entryPoint, setEntryPoint] = useState('')
  const [issuer, setIssuer] = useState('')
  const [domains, setDomains] = useState([])
  const [originalConfig, setOriginalConfig] = useState({})
  const [hasChange, setHasChange] = useState(false)
  const [validationErrors, dispatchErrors] = useReducer(
    errorStateReducer,
    initialErrorState
  )
  const hasValidationErrors = errors =>
    Object.values(errors).some(errField => errField.length !== 0)
  const hasFieldValidationErrors = field => validationErrors[field].length !== 0

  const setSSOConfig = useCallback(config => {
    setOriginalConfig(config)
    setEnabled(config.enabled ?? false)
    setPublicKey(config.publicKey ?? '')
    setEntryPoint(config.entryPoint ?? '')
    setIssuer(config.issuer ?? '')
    setDomains(config.domains ?? [])
    setAutoProvisionUsers(config.autoProvisionUsers ?? false)
  }, [])

  const getSSOConfig = useGetSSOConfig(setSSOConfig)

  const deleteSSOConfig = useDeleteSSOConfig(
    () => {
      setModalOpen(false)
      toast.success('SSO configuration removed successfully')
    },
    () => {
      setLoadError('Could not delete SSO configuration')
    }
  )

  const validate = ({ newEntryPoint, newIssuer, newPublicKey, newDomains }) => {
    const newValidationErrors = { ...validationErrors }

    if (newEntryPoint !== undefined) {
      newValidationErrors.entrypoint = []

      if (!newEntryPoint) {
        newValidationErrors.entrypoint.push('SAML Sign-in URL is required')
      }
      if (!isWebUri(newEntryPoint)) {
        newValidationErrors.entrypoint.push(
          'SAML Sign-in URL is not a valid URL'
        )
      }
    }

    if (newIssuer !== undefined) {
      newValidationErrors.issuer = []

      if (!newIssuer) {
        newValidationErrors.issuer.push('SAML Issuer is required')
      }
    }

    if (newPublicKey !== undefined) {
      newValidationErrors.publicKey = []

      if (!newPublicKey) {
        newValidationErrors.publicKey.push('Public Key is required')
      }
    }

    if (newDomains !== undefined) {
      newValidationErrors.domains = []

      if (newDomains.length === 0) {
        newValidationErrors.domains.push('At least one domain is required')
      }
    }

    dispatchErrors({ type: 'SET_ALL', payload: newValidationErrors })
    return newValidationErrors
  }

  const submitSSOConfig = useSubmitSSOConfig(
    () => toast.success('SSO configuration saved successfully'),
    () => toast.error('Could not save SSO configuration')
  )

  const compareArrays = (a, b) =>
    a.length === b.length && a.every((element, index) => element === b[index])

  const removeSSOConfig = async () => {
    setModalOpen(true)
  }

  useEffect(() => {
    setHasChange(
      enabled !== originalConfig.enabled ||
        publicKey !== originalConfig.publicKey ||
        entryPoint !== originalConfig.entryPoint ||
        issuer !== originalConfig.issuer ||
        autoProvisionUsers !== originalConfig.autoProvisionUsers ||
        !compareArrays(domains, originalConfig.domains)
    )
  }, [
    domains,
    enabled,
    entryPoint,
    issuer,
    originalConfig,
    publicKey,
    autoProvisionUsers,
  ])

  if (getSSOConfig.isError) {
    return (
      <Alerts title='Error' alert='error' margin='my-6'>
        <p>{loadError}</p>
      </Alerts>
    )
  }

  if (getSSOConfig.isLoading) {
    return <Skeleton count={5} />
  }

  return (
    <>
      <Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
        <h3 className='text-center mb-2'>Remove SSO Configuration?</h3>
        <p>Are you sure you want to remove this SSO configuration?</p>
        <p>
          This action cannot be undone, and users will no longer be able to sign
          in using SSO.
        </p>
        <ModalActionButtons
          onCancel={() => setModalOpen(false)}
          onConfirm={deleteSSOConfig.mutate}
          confirmActionText='Delete'
        />
      </Modal>
      <>
        <h4 className='mb-4'>Single Sign On (SSO) Configuration</h4>
        <Alerts title='Single Sign On (SSO)' alert='info' margin='my-4'>
          <p>
            Single Sign On (SSO) allows users to sign in to Inforcer using their
            existing credentials from Entra ID using SAML.
          </p>
          <p>
            When SSO is enabled, users will be redirected to the SSO provider to
            sign in. If successful, they will be redirected back to Inforcer.
          </p>
        </Alerts>
        {hasValidationErrors(validationErrors) && (
          <Alerts title='Error' alert='error' margin='my-6'>
            {[].concat(...Object.values(validationErrors)).map(error => (
              <p key={error}>{error}</p>
            ))}
          </Alerts>
        )}
        <form
          className='flex flex-col gap-6'
          onSubmit={event => {
            event.preventDefault()
            const validationErrs = validate({
              newEntryPoint: entryPoint,
              newPublicKey: publicKey,
              newIssuer: issuer,
              newDomains: domains,
            })
            if (hasValidationErrors(validationErrs)) return null

            submitSSOConfig.mutate({
              enabled,
              publicKey,
              entryPoint,
              issuer,
              domains,
              autoProvisionUsers,
            })
          }}
        >
          <SSOCheckbox
            checked={enabled}
            onChange={setEnabled}
            label='SSO Enabled?'
            id='ssoEnabled'
            textHint='Enable Single Sign On (SSO) for your users? If this is disabled, users will only be able to sign in using their email and password.'
          />
          <HorizontalDivider />
          <div>
            <label
              htmlFor='entryPoint'
              className={
                hasFieldValidationErrors('entrypoint') ? 'label-error' : null
              }
            >
              SAML Sign-in URL
              <input
                placeholder='(e.g. https://login.microsoftonline.com/&#123;tenant-id&#125;/saml2)'
                id='entryPoint'
                name='entryPoint'
                type='text'
                className={`flex rounded-md border p-2 ${hasFieldValidationErrors('entrypoint') ? 'border-red-500' : 'border-gray-400'} bg-white text-gray-700 focus:outline-blue-400 sso-input`}
                value={entryPoint}
                disabled={!enabled}
                onChange={e => {
                  e.preventDefault()
                  setEntryPoint(e.target.value.trim())
                }}
                onBlur={() => validate({ newEntryPoint: entryPoint })}
              />
            </label>
          </div>
          <div>
            <label
              htmlFor='issuer'
              className={
                hasFieldValidationErrors('issuer') ? 'label-error' : null
              }
            >
              SAML Issuer
              <input
                id='issuer'
                placeholder='The ‘Identifier (Entity ID)’ attribute'
                name='issuer'
                type='text'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400 sso-input'
                value={issuer}
                disabled={!enabled}
                onChange={e => {
                  e.preventDefault()
                  setIssuer(e.target.value.trim())
                }}
                onBlur={() => validate({ newIssuer: issuer })}
              />
            </label>
          </div>
          <div>
            <span
              className={`sso-label ${hasFieldValidationErrors('domains') ? 'label-error' : null}`}
            >
              Domains
            </span>
            <span className='sso-help-text'>
              One or more domains that are permitted to sign in via SSO.{' '}
              <strong>At least one domain is required</strong>
            </span>
            <SSODomainsInput
              placeholder='Add domain'
              onDomainChange={newData => {
                setDomains(newData)
                validate({ newDomains: newData })
              }}
              values={domains}
              maxDomains={5}
              disabled={!enabled}
              onBlur={validate}
            />
          </div>
          <div>
            <span
              className={`sso-label ${hasFieldValidationErrors('publicKey') ? 'label-error' : null}`}
            >
              Public Key
            </span>
            <span className='sso-help-text'>
              The certificate used to verify SSO requests
            </span>
            <SSOFileInput
              disabled={!enabled}
              fileContent={publicKey}
              setFileContent={setPublicKey}
              onFileSubmit={newPublicKey => validate({ newPublicKey })}
            />
          </div>
          <SSOCheckbox
            checked={autoProvisionUsers}
            onChange={setAutoProvisionUsers}
            label='Auto-provision Users?'
            id='autoProvisionUsers'
            disabled={!enabled}
            textHint='Auto-provision users in Inforcer when they sign in via SSO'
          />
          <div>
            <button
              className='btn cyan-btn mr-4 mb-2'
              type='submit'
              disabled={hasValidationErrors(validationErrors) || !hasChange}
            >
              <FontAwesomeIcon
                icon={faCloudUpload}
                size='lg'
                className='pr-2'
              />
              Save Changes
            </button>
            <button
              className='btn navy-btn mr-4 mb-2'
              type='button'
              onClick={() => setSSOConfig(originalConfig)}
              disabled={!hasChange}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                size='lg'
                className='pr-2'
              />
              Discard Changes
            </button>
            <button
              className='btn bg-red-800 text-white'
              type='button'
              onClick={removeSSOConfig}
            >
              <FontAwesomeIcon icon={faTrash} size='lg' className='pr-2' />
              Delete Configuration
            </button>
          </div>
        </form>
      </>
    </>
  )
}

export default SSOAdmin
