import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetListTenants = () =>
  useQuery({
    queryKey: ['list-tenants'],
    queryFn: async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
      )
      return response.data
    },
  })

export default useGetListTenants
