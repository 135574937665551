import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'

const useGetListTenants = () =>
  useQuery({
    queryKey: ['list-tenants'],
    gcTime: 0,
    queryFn: async () => {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
      )
      return response.data.Data
    },
  })

export default useGetListTenants
