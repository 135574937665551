import Axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

export const useTenants = () => {
  const [initialised, setInitialised] = useState(false)

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState()

  const [tenants, setTenants] = useState([])

  const loadTenants = useCallback(async () => {
    try {
      setLoading(true)
      setError(undefined)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
      )
      if (response.status < 200 || response.status >= 300) {
        setError('Failed to fetch tenants')
      }

      setTenants(response.data.Data)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (initialised) return
    setInitialised(true)
    loadTenants()
  }, [initialised, loadTenants])

  return {
    loading,
    error,
    tenants,
    loadTenants,
  }
}
