import { string, number, bool, func, arrayOf, shape } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const TargetTenants = ({
  targetTenants,
  tenantSearchValue,
  onTenantSearchChange,
  selectedTargetTenant,
  setSelectedTargetTenant,
}) => {
  const targetTenantClick = ({ clientTenantId, tenantFriendlyName }) => {
    if (selectedTargetTenant?.clientTenantId === clientTenantId) {
      setSelectedTargetTenant(null)
    } else {
      const updatedTenant = {
        tenantFriendlyName,
        clientTenantId,
      }
      setSelectedTargetTenant(updatedTenant)
    }
    onTenantSearchChange('')
  }

  const clearSearch = () => {
    setSelectedTargetTenant(null)
    onTenantSearchChange('')
  }

  // sort Golden tenants to top
  const sortedTenants = [...targetTenants].sort(
    (a, b) => b.isGoldenTenant - a.isGoldenTenant
  )

  return (
    <div>
      {selectedTargetTenant ? (
        <div className='select-tenant-heading'>
          <h4>Selected Tenant: </h4>
          <p className='pl-1'>{selectedTargetTenant.tenantFriendlyName}</p>
        </div>
      ) : (
        <div className='select-tenant-heading'>
          <h4>Select Tenant</h4>
          <p>&nbsp;</p>
        </div>
      )}
      <div className='flex items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mb-[20px] mt-[20px]'
          type='text'
          placeholder='Search'
          value={tenantSearchValue}
          onChange={e => onTenantSearchChange(e.target.value)}
        />
        <button className='btn navy-btn clear-btn' onClick={clearSearch}>
          Clear
        </button>
      </div>
      <div className='table-container'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td className='tenant-table-th tags-th'>Tags</td>
              <td className='tenant-table-th tenant-name-th'>Tenant Name</td>
              <td
                className='tenant-table-th baseline-th'
                aria-label='Is Selected'
              />
            </tr>
            {sortedTenants
              .filter(tenant => tenant && tenant.clientTenantId)
              .map(tenant => (
                <tr
                  key={tenant.msTenantId}
                  onClick={() => targetTenantClick(tenant)}
                  data-client-tenant-id={tenant.clientTenantId}
                  className={`tenant-row cursor-pointer ${
                    selectedTargetTenant?.clientTenantId ===
                    tenant.clientTenantId
                      ? 'selected'
                      : ''
                  }`}
                >
                  <td className='baseline-cell'>
                    {tenant.isGoldenTenant && (
                      <span className='baseline-tag'>Baseline</span>
                    )}
                  </td>
                  <td className='tenant-name'>
                    <span className='tenant-name-span'>
                      {tenant.tenantFriendlyName}&nbsp;
                    </span>
                  </td>
                  <td className='check-cell'>
                    {selectedTargetTenant?.clientTenantId ===
                    tenant.clientTenantId ? (
                      <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                    ) : null}
                  </td>
                </tr>
              ))}

            {sortedTenants.length === 0 && (
              <tr>
                <td colSpan='4'>No tenants found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

TargetTenants.propTypes = {
  targetTenants: arrayOf(
    shape({
      clientTenantId: number.isRequired,
      tenantFriendlyName: string.isRequired,
      isGoldenTenant: bool,
      msTenantId: string.isRequired,
      selectedTargetTenant: shape({
        clientTenantId: string,
        tenantFriendlyName: string,
      }),
    })
  ).isRequired,
  tenantSearchValue: string.isRequired,
  onTenantSearchChange: func.isRequired,
  setSelectedTargetTenant: func.isRequired,
}

export default TargetTenants
