import { useState } from 'react'
import { node, oneOf, string } from 'prop-types'
import './Tooltip.css'

const Tooltip = ({ content, children, width, position }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const positionClass = position === 'bottom' ? 'bottom' : 'top'

  return (
    <div className='relative'>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className='flex items-center'
        onKeyUp={() => setShowTooltip(!showTooltip)}
        onClick={() => setShowTooltip(!showTooltip)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onFocus={() => setShowTooltip(true)}
        onBlur={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip && (
        <div
          aria-hidden={!showTooltip}
          role='tooltip'
          className={`tooltip-content absolute left-1/2 transform -translate-x-1/2 rounded-md py-1 px-2 mb-5 pointer-events-none transition-all duration-300 z-50 ${positionClass} ${width}`}
        >
          {content}
          <div className={`tooltip-arrow ${positionClass}`} />
        </div>
      )}
    </div>
  )
}

Tooltip.defaultProps = {
  width: '',
  position: 'top',
}

Tooltip.propTypes = {
  content: node.isRequired,
  children: node.isRequired,
  width: string,
  position: oneOf(['top', 'bottom']),
}

export default Tooltip
