import { arrayOf, shape, string } from 'prop-types'

const TagsCell = ({ tags }) => (
  <div className='flex gap-1'>
    {tags.map(tag => (
      <span key={tag.text} className={`list-table-tag ${tag.styles}`}>
        {tag.text}
      </span>
    ))}
  </div>
)

TagsCell.propTypes = {
  tags: arrayOf(
    shape({
      styles: string,
      text: string.isRequired,
    })
  ).isRequired,
}

export default TagsCell
