import { useMutation } from '@tanstack/react-query'
import { useFeatureFlag } from 'configcat-react'

import axios from 'axios'

const useAcceptDeviation = (onSuccess, onError) => {
  const { value: useNewDeviationEndpoint } = useFeatureFlag(
    'enableBulkDeviations',
    false
  )

  return useMutation({
    mutationFn: async data => {
      let response

      if (useNewDeviationEndpoint) {
        response = await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/deviationAcceptance`,
          { data }
        )
      } else {
        const { policies, ...rest } = data

        response = await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/postDeviationAcceptance`,
          { ...rest, ...data.policies[0] }
        )
      }

      return response.data.data
    },
    onSuccess: data => {
      onSuccess(data)
    },
    onError,
  })
}
export default useAcceptDeviation
