import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const AddOBCode = () => {
  const [clients, setClients] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const [formData, setFormData] = useState({
    onboardingTokenName: '',
    clientId: '',
    allowedClients: '',
  })
  const [fieldsError, setFieldsError] = useState('')
  const [requestError, setRequestError] = useState('')
  const [newData, setNewData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    clientSearch()
  }, [])

  const clientSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response

      setClients(data)
    } catch (error) {
      if (error.response.status === 401) {
        toast.info('Your session has expired. Please log back in.')

        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }
    }
  }

  const handleMailChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const handleClientChange = e => {
    const selectedClientId = e.target.value
    setFormData(prevData => ({
      ...prevData,
      clientId: selectedClientId,
    }))
  }

  const submitData = async () => {
    if (
      !formData.clientId ||
      !formData.allowedClients ||
      !formData.onboardingTokenName
    ) {
      setFieldsError('All form fields are required')
      return
    }

    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/addOnboardingToken`,
        {
          clientId: formData.clientId,
          allowedClients: formData.allowedClients,
          onboardingToken: formData.onboardingTokenName,
        }
      )

      let data = response.data.Data
      if (!Array.isArray(data)) {
        data = [data]
      }

      setNewData(data)
      setFieldsError('')
      setFormData({ onboardingTokenName: '', clientId: '', allowedClients: '' })
    } catch (error) {
      setRequestError('Error. Please check the details and try again.')
      setNewData([])
      setFieldsError('')
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Add OB Code</h2>
      </div>
      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>

      <div className='action-body no-progress-bar flex'>
        <form className='w-[50%] max-w-lg'>
          {fieldsError && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{fieldsError}</span>
            </div>
          )}
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='onboardingTokenName'>
              Onboarding Token Name:
            </label>
            <input
              id='onboardingTokenName'
              type='text'
              name='onboardingTokenName'
              value={formData.onboardingTokenName || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            />
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectClient'>
              Select Client:
            </label>
            <select
              id='selectClient'
              value={formData.clientId}
              onChange={handleClientChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a client</option>
              {clients.map(client => (
                <option
                  name={client.displayName}
                  key={`${client.clientId}-${client.displayName}`}
                  value={client.clientId}
                >
                  {client.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label htmlFor='allowedClients' className='font-semibold'>
              Allowed Clients:
            </label>
            <input
              id='allowedClients'
              type='number'
              min='0'
              name='allowedClients'
              value={formData.allowedClients || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 '
            />
          </div>

          <button
            type='button'
            onClick={submitData}
            className='btn cyan-btn mt-4'
          >
            Submit
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4'>
          <h4 className='mb-[10px]'>Result:</h4>

          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : newData ? (
            newData.map(user => (
              <div key={`${user.clientId}-${user.onboardingCodeId}`}>
                <p>
                  <strong>Client Id: </strong>
                  <span>{user.clientId}</span>
                </p>
                <p>
                  <strong>Onboarding Code: </strong>
                  <span>{user.onboardingCode}</span>
                </p>
                <p>
                  <strong>Onboarding Code ID: </strong>
                  <span>{user.onboardingCodeId}</span>
                </p>
                <p>
                  <strong>Expiry Date: </strong>
                  <span>{user.expiryDate}</span>
                </p>
                <p>
                  <strong>Allowed Onboardings: </strong>
                  <span>{user.allowedOnboardings}</span>
                </p>
              </div>
            ))
          ) : requestError ? (
            <p>{requestError}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AddOBCode
