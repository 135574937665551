import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { object } from 'prop-types'
import Alerts from '../alerts/Alerts'
import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'

const SSOAddUserToolbar = ({ addUserMutation }) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [displayName, setDisplayName] = useState('')
  const [displayNameError, setDisplayNameError] = useState(null)

  const validateEmail = input => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(input)
  }

  const validate = () => {
    let emailErr = null
    let displayNameErr = null

    if (!email) {
      emailErr = 'Email address is required'
    } else if (!validateEmail(email)) {
      emailErr = 'Email address is not valid'
    } else if (email.length < 5 || email.length > 200) {
      emailErr = 'Email address must be between 5 and 200 characters'
    }

    if (!displayName) {
      displayNameErr = 'Display Name is required'
    } else if (displayName.length < 1 || displayName.length > 100) {
      displayNameErr = 'Display Name must be between 1 and 100 characters'
    }

    setEmailError(emailErr)
    setDisplayNameError(displayNameErr)

    return emailErr || displayNameErr
  }

  const onCancelAddUser = () => {
    setModalOpen(false)
    setEmail('')
    setDisplayName('')
    setEmailError(null)
    setDisplayNameError(null)
    setHasError(false)
  }

  const onConfirmAddUser = () => {
    const validationErrors = validate()

    if (validationErrors) {
      return
    }

    addUserMutation.mutate({ email, displayName })
    setModalOpen(false)
    setEmail('')
    setDisplayName('')
    setEmailError(null)
    setDisplayNameError(null)
  }

  useEffect(() => {
    setHasError(!!emailError || !!displayNameError)
  }, [emailError, displayNameError])

  return (
    <>
      <Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
        <div className='action-body no-progress-bar flex'>
          <form className='w-[100%]' onSubmit={onConfirmAddUser}>
            <Alerts title='Add User' alert='info' margin='my-4'>
              <p>
                Enter the email address and display name of the user you want to
                add.
              </p>
              <p>
                Users <strong>must</strong> have a valid email address
                associated with an SSO domain you have configured.
              </p>
            </Alerts>
            {hasError && (
              <Alerts title='Errors' alert='error' margin='my-6'>
                {emailError && <p>{emailError}</p>}
                {displayNameError && <p>{displayNameError}</p>}
              </Alerts>
            )}
            <div className='flex'>
              <div className='flex-child flex-1 space-y-2 my-4 mr-4'>
                <label className='font-semibold' htmlFor='user-email'>
                  Email <span className='text-red-500'>*</span>
                  <input
                    type='email'
                    name='user-email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className={`w-[100%] flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 ${emailError ? 'border-red-500' : null}`}
                  />
                </label>
              </div>
              <div className='flex-child flex-1 space-y-2 my-4 mr-4'>
                <label className='font-semibold' htmlFor='user-displayName'>
                  Display Name <span className='text-red-500'>*</span>
                  <input
                    type='text'
                    name='user-displayName'
                    value={displayName}
                    onChange={e => setDisplayName(e.target.value)}
                    className={`w-[100%] flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 ${displayNameError ? 'border-red-500' : null}`}
                  />
                </label>
              </div>
            </div>
            <ModalActionButtons
              onCancel={onCancelAddUser}
              onConfirm={onConfirmAddUser}
              confirmActionText='Add User'
              confirmEnabled={!hasError}
            />
          </form>
        </div>
      </Modal>
      <button
        type='button'
        className='btn cyan-btn mr-4'
        onClick={() => setModalOpen(true)}
      >
        <FontAwesomeIcon icon={faUserPlus} className='mr-2' />
        Add User
      </button>
    </>
  )
}

SSOAddUserToolbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  addUserMutation: object.isRequired,
}

export default SSOAddUserToolbar
