import {
  faArrowUpRightFromSquare,
  faCheckCircle,
  faCircleMinus,
  faCloudDownload,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConnectionStatusIcon from './ConnectionStatusIcon'
import PolicyDiffContent from './PolicyDiffContent'
import { formatToLocalTimeLong } from './utils'

const tableColumnOrder = [
  'tenantFriendlyName',
  'alignedBaseline',
  'secureScore',
  'alignmentScore',
  'lastAlignmentTimestamp',
  'lastBackupTimestamp',
  'recentChanges',
  'isBaseline',
  'connectionStatus',
]

const connectionStatusFilter = (row, id, filterValue) => {
  if (filterValue === 'Connected') return row.original.connectionStatus === true
  if (filterValue === 'Disconnected')
    return row.original.connectionStatus === false
  if (filterValue === 'Unknown') return row.original.connectionStatus === null
  return true
}

const tableColumnDefinitions = setModalContent => [
  {
    header: 'Connection Status',
    accessorKey: 'connectionStatus',
    size: '40',
    filterVariant: 'select',
    enableSorting: false,
    filterSelectOptions: ['Connected', 'Disconnected', 'Unknown'],
    filterFn: 'connectionStatus',
    Header: () => (
      <FontAwesomeIcon
        icon={faCloudDownload}
        color='rgba(var(--navy), 0.9)'
        size='xl'
      />
    ),
    Cell: ({ row }) => (
      <ConnectionStatusIcon connectionStatus={row.original.connectionStatus} />
    ),
  },
  {
    header: 'Tenant Name',
    accessorKey: 'tenantFriendlyName',
    filterVariant: 'autocomplete',
    size: '100',
    Header: () => (
      <div>
        <strong>Tenant Name</strong>
        <p className='font-normal text-sm opacity-75'>DNS name</p>
      </div>
    ),
    Cell: ({ renderedCellValue, row }) => (
      <div>
        <strong>{renderedCellValue}</strong>
        <p className='text-sm opacity-75'>{row.original.tenantDnsName}</p>
      </div>
    ),
  },
  {
    header: 'Is Baseline',
    accessorKey: 'isBaseline',
    filterVariant: 'checkbox',
    size: '100',
    Cell: ({ cell }) => (
      <div className='flex justify-center'>
        <FontAwesomeIcon
          icon={cell.getValue() ? faCheckCircle : faCircleMinus}
          color={cell.getValue() ? 'rgba(var(--navy), 0.9)' : 'grey'}
          size='xl'
        />
      </div>
    ),
  },
  {
    header: 'Baseline Group',
    accessorKey: 'alignedBaseline',
    filterVariant: 'autocomplete',
    size: '100',
    Cell: ({ renderedCellValue }) => (
      <p className={!renderedCellValue ? 'opacity-75' : ''}>
        {renderedCellValue || 'Not Assigned'}
      </p>
    ),
  },
  {
    header: 'Alignment Score',
    accessorKey: 'alignmentScore',
    filterVariant: 'range',
    size: '40',
    Cell: ({ cell, row }) => {
      const alignmentScore = cell.getValue()
      const isNotAligned = alignmentScore < row.original.semiAlignedThreshold
      const isSemiAligned =
        alignmentScore > row.original.semiAlignedThreshold &&
        alignmentScore < row.original.alignedThreshold
      const alignmentColour = isNotAligned
        ? 'bg-red-600 text-white'
        : isSemiAligned
          ? 'bg-opacity-90 bg-amber-500 text-white'
          : 'bg-opacity-85 bg-green-600 text-white'
      if (alignmentScore)
        return (
          <div
            className={`w-fit h-fit rounded-lg inline-block py-1 px-2 ${row.original.isBaseline ? 'bg-opacity-60 bg-gray-500 text-white' : alignmentColour}`}
          >
            <p>{alignmentScore}%</p>
          </div>
        )
      return <p className='opacity-75'>Not Calculated Yet</p>
    },
  },
  {
    header: 'Secure Score',
    accessorKey: 'secureScore',
    filterVariant: 'range',
    size: '40',
    Cell: ({ cell }) => {
      const secureScore = cell.getValue()
      if (secureScore) return <p>{secureScore}%</p>
      return <p className='opacity-75'>Not Calculated Yet</p>
    },
  },
  {
    header: 'Changes in Last Backup',
    accessorKey: 'recentChanges',
    size: '40',
    Cell: ({ cell, row }) => {
      const recentChanges = cell.getValue()

      if (recentChanges === 0) return <p>0</p>

      if (recentChanges > 0)
        return (
          <button
            type='button'
            onClick={() =>
              setModalContent(
                <PolicyDiffContent policyDiff={row.original.policyDiff} />
              )
            }
          >
            {recentChanges}
            <FontAwesomeIcon
              className='pl-1 opacity-80'
              title='View recent changes diff'
              icon={faArrowUpRightFromSquare}
              size='sm'
            />
          </button>
        )
    },
  },
  {
    header: 'Last Successful Backup',
    accessorKey: 'lastBackupTimestamp',
    accessorFn: originalRow => {
      if (originalRow.lastBackupTimestamp === null) return null
      return new Date(originalRow.lastBackupTimestamp)
    },
    filterVariant: 'datetime-range',
    Cell: ({ cell }) => {
      const timestamp = cell.getValue()
      if (timestamp) return <p>{formatToLocalTimeLong(timestamp)}</p>
      return <p className='opacity-75'>No Backup Run</p>
    },
  },
  {
    header: 'Last Successful Alignment',
    accessorKey: 'lastAlignmentTimestamp',
    accessorFn: originalRow => {
      if (originalRow.lastAlignmentTimestamp === null) return null
      return new Date(originalRow.lastAlignmentTimestamp)
    },
    filterVariant: 'datetime-range',
    Cell: ({ cell, row }) => {
      const timestamp = cell.getValue()
      if (timestamp) return <p>{formatToLocalTimeLong(timestamp)}</p>
      if (timestamp === null && row.original.isBaseline)
        return <p className='opacity-75'>N/A for baselines</p>
      return <p className='opacity-75'>No Alignment Run</p>
    },
  },
]

export { tableColumnDefinitions, connectionStatusFilter, tableColumnOrder }
