import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PolicyPickerSkeleton = ({ loadingTitle }) => (
  <>
    <h4 className='mb-3'>{loadingTitle}</h4>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
  </>
)

PolicyPickerSkeleton.propTypes = {
  loadingTitle: PropTypes.string.isRequired,
}

export default PolicyPickerSkeleton
