import { useState } from 'react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'

import useGetTenantsByClients from '../api/useGetTenantsByClients'

import SearchBar from './SearchBar'
import SharedBaselinesSkeleton from './SharedBaselineGroupSkeleton'

const BaselinesSelect = () => {
  const [isAllSelected, setIsAllSelected] = useState(false)

  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext()

  const searchBaselines = useWatch({
    name: 'searchBaselines',
    defaultValue: '',
  })

  const clientId = useWatch({
    control,
    name: 'clientId',
  })

  const publishedBaselines = useWatch({
    control,
    name: 'publishedBaselines',
    defaultValue: [],
  })

  const {
    data: baselines = [],
    error,
    isError,
    isLoading,
  } = useGetTenantsByClients(clientId)

  const filteredBaselines =
    baselines.length !== 0 &&
    baselines.Data.filter(
      baseline =>
        baseline.isGoldenTenant &&
        baseline.tenantFriendlyName
          .toLowerCase()
          .includes(searchBaselines.toLowerCase())
    )

  const handleSelectBaselines = baselineId => event => {
    const newCheckedItems = [...publishedBaselines]
    if (event.target.checked) {
      newCheckedItems.push(baselineId)
    } else {
      const index = newCheckedItems.indexOf(baselineId)
      if (index !== -1) {
        newCheckedItems.splice(index, 1)
      }
    }
    setValue('publishedBaselines', newCheckedItems)
  }

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setValue('publishedBaselines', [])
    } else {
      setValue(
        'publishedBaselines',
        filteredBaselines.map(baseline => baseline.clientTenantId)
      )
    }
    setIsAllSelected(!isAllSelected)
  }

  if (isLoading) {
    return (
      <div className='p-4 border mt-2'>
        <SharedBaselinesSkeleton loadingTitle='Loading published baselines...' />
      </div>
    )
  }

  if (isError) {
    return (
      <div className='p-4 border mt-2'>
        <div className='mt-3'>
          <p>{error.message}</p>
        </div>
      </div>
    )
  }

  if (!filteredBaselines.length) {
    return (
      <div className='p-4 border mt-2'>
        <div className='mt-3'>
          <p>No baselines found</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='font-semibold mt-2'>
        Available baselines for selected client
      </div>
      <div
        className={`p-4 border ${
          errors.publishedBaselines
            ? 'border-red-500'
            : 'focus:ring-blue-500 focus:border-blue-500'
        }`}
      >
        <SearchBar
          fieldName='searchBaselines'
          type='checkbox'
          isAllSelected={isAllSelected}
          toggleSelectAll={handleSelectAllChange}
        />
        <div className='mt-2'>
          {filteredBaselines.map(baseline => {
            const baselineId = baseline.clientTenantId
            return (
              <div key={baselineId} className='flex items-center mb-2'>
                <Controller
                  control={control}
                  rules={{ required: 'Baselines is required' }}
                  name='publishedBaselines'
                  render={() => (
                    <input
                      type='checkbox'
                      id={`baseline-${baselineId}`}
                      value={baselineId}
                      checked={publishedBaselines.includes(baselineId)}
                      onChange={handleSelectBaselines(baselineId)}
                      className='mr-2'
                    />
                  )}
                />
                <label htmlFor={`baseline-${baselineId}`}>
                  {baseline.tenantFriendlyName}
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default BaselinesSelect
