import PropTypes, { objectOf, bool, func, string } from 'prop-types'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { validateEmail } from '../utils/Validation'

const OnboardingSetupTenant = ({
  state,
  loading,
  title,
  onUpdateMetadata,
  onOnboardCustomer,
  onSkip,
  isRefresh = false,
  tenantAppId,
}) => {
  const navigate = useNavigate()

  const openTenants = () => {
    navigate('/tenant-management')
  }

  const currentProgress = useMemo(
    () => state.customerProgress.find(p => p.status !== 'complete'),
    [state.customerProgress]
  )

  const currentCustomer = useMemo(
    () =>
      state.customers.find(
        customer =>
          customer.id === currentProgress?.customerId &&
          customer.partnerCenter === currentProgress?.partnerCenter
      ),
    [currentProgress, state.customers]
  )

  const currentIndex = useMemo(
    () =>
      currentProgress ? state.customerProgress.indexOf(currentProgress) : -1,
    [currentProgress, state.customerProgress]
  )

  const metadataComplete =
    !!currentProgress &&
    !!currentProgress?.clientReference &&
    !!currentProgress?.notificationAddress &&
    validateEmail(currentProgress?.notificationAddress)

  const buildSubStep = () => {
    if (!currentProgress) return null

    switch (currentProgress.status) {
      case 'idle':
        if (!isRefresh)
          return (
            <>
              <p className='mb-4'>
                Please enter the following required metadata for this tenant.
                Once all the details have been confirmed, you will need to grant
                the relevant permissions for this tenant.
              </p>
              <div>
                <div className='mb-4'>
                  <label htmlFor='clientfriendlyname'>
                    <b>Client Friendly Name</b>
                  </label>
                  <input
                    type='text'
                    className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
                    value={currentProgress.friendlyName ?? ''}
                    onChange={v =>
                      onUpdateMetadata(currentProgress.customerId, {
                        friendlyName: v.target.value,
                      })
                    }
                  />
                </div>
                <div className='mb-4'>
                  <label htmlFor='clientreference'>
                    <b>Client Reference</b>
                  </label>
                  <input
                    type='text'
                    className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
                    value={currentProgress.clientReference ?? ''}
                    onChange={v =>
                      onUpdateMetadata(currentProgress.customerId, {
                        clientReference: v.target.value,
                      })
                    }
                  />
                </div>
                <div className='mb-4'>
                  <label htmlFor='notificationaddressemail'>
                    <b>Notification Address (Email)</b>
                  </label>
                  <input
                    type='email'
                    className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
                    value={currentProgress.notificationAddress ?? ''}
                    onChange={v =>
                      onUpdateMetadata(currentProgress.customerId, {
                        notificationAddress: v.target.value,
                      })
                    }
                  />
                </div>
                <div className='mb-8'>
                  <label htmlFor='baseline-tenant-checkbox'>
                    <b>Mark as Baseline Tenant</b>
                    <input
                      id='baseline-tenant-checkbox'
                      className='ml-4'
                      type='checkbox'
                      checked={currentProgress.isGoldenTenant}
                      onChange={v =>
                        onUpdateMetadata(currentProgress.customerId, {
                          isGoldenTenant: v.target.checked,
                        })
                      }
                    />
                  </label>
                </div>
                <div>
                  <button
                    type='button'
                    className={`btn cyan-btn mr-4${!metadataComplete ? ' disabled' : ''}`}
                    disabled={!metadataComplete}
                    onClick={() =>
                      onOnboardCustomer(
                        currentProgress.customerId,
                        tenantAppId ?? '',
                        currentProgress.partnerCenter
                      )
                    }
                  >
                    Confirm & Grant Permission
                  </button>
                  {isRefresh && (
                    <button
                      type='button'
                      className='btn tertiary-btn'
                      onClick={() => onSkip(currentProgress.customerId)}
                    >
                      Skip Customer
                    </button>
                  )}
                </div>
              </div>
            </>
          )
        return (
          <>
            <p className='mb-4'>
              You are about to refresh this tenant with default data. Your
              tenant&apos;s global admin credentials will need to be provided
              during this process.
            </p>
            <div>
              <div>
                <button
                  type='button'
                  className='btn cyan-btn mr-4'
                  onClick={() =>
                    onOnboardCustomer(
                      currentProgress.customerId,
                      tenantAppId,
                      currentProgress.partnerCenter
                    )
                  }
                >
                  Confirm & Grant Permission
                </button>
                <button
                  type='button'
                  className='btn tertiary-btn'
                  onClick={() => onSkip(currentProgress.customerId)}
                >
                  Skip Refresh
                </button>
              </div>
            </div>
          </>
        )
      case 'running':
        return (
          <>
            <p className='mb-4'>
              Please press &apos;Start Onboarding&apos; to begin the process.
            </p>
            <p className='mb-4'>
              <b>
                You may need to sign in again as a global administrator account
                for this tenant to continue.
              </b>
            </p>
            <div className='rounded-md border p-4 border-gray-400 bg-white text-gray-700 my-[20px]'>
              {currentProgress.log.length === 0 ? (
                <p>Onboarding ready to begin...</p>
              ) : (
                currentProgress.log.map((e, i) => (
                  <p
                    className={`${e.status === 'error' ? 'text-red' : ''}`}
                    key={i}
                  >
                    {e.message}
                  </p>
                ))
              )}
            </div>
            {loading ? (
              <div className='flex mb-4'>
                <div id='loading-spinner' className='mr-4'>
                  <div className='loading' />
                </div>
                <p className='my-1'>
                  Tenant onboarding, please do not refresh your browser.
                </p>
              </div>
            ) : null}
            {currentCustomer ? (
              <button
                type='button'
                className='btn cyan-btn'
                disabled={loading}
                onClick={() => {
                  onOnboardCustomer(
                    currentProgress.customerId,
                    null,
                    currentProgress.partnerCenter
                  )
                }}
              >
                {loading ? 'Please Wait' : 'Start Onboarding'}
              </button>
            ) : null}
          </>
        )
      case 'error':
        return (
          <>
            <p className='mb-4'>
              One or more critical errors were encountered, please contact an
              administrator.
            </p>
            <div className='rounded-md border p-4 border-gray-400 bg-white text-gray-700 my-[20px]'>
              {currentProgress.log.map((e, i) => (
                <p
                  className={`${e.status === 'error' ? 'text-red' : ''}`}
                  key={i}
                >
                  {e.message}
                </p>
              ))}
            </div>
          </>
        )
      case 'complete':
      default:
        return null
    }
  }

  return (
    <>
      <h3 className='mb-4'>{title}</h3>
      <h4 className='mb-4'>
        {state.customerProgress.length > 1
          ? `[${currentIndex + 1}/${state.customerProgress.length}] `
          : ''}

        {currentCustomer &&
          `${currentCustomer?.companyProfile.companyName} - ${currentCustomer?.companyProfile.domain}`}

        {state.customerProgress.length !== 0 &&
          state.customerProgress.some(p => p.status === 'complete') && (
            <>
              <h3 className='mb-4'>Permissions Updated</h3>
              <p className='mb-4'>
                The app registration permissions have been updated.
              </p>
              <button
                type='button'
                className='btn cyan-btn'
                onClick={openTenants}
              >
                View Tenants
              </button>
            </>
          )}
      </h4>
      {buildSubStep()}
    </>
  )
}

OnboardingSetupTenant.defaultProps = {
  title: 'Step 3: Onboard tenants',
  isRefresh: false,
  tenantAppId: null,
}

OnboardingSetupTenant.propTypes = {
  state: objectOf(PropTypes.any).isRequired,
  loading: bool.isRequired,
  title: string,
  onUpdateMetadata: func.isRequired,
  onOnboardCustomer: func.isRequired,
  onSkip: func.isRequired,
  isRefresh: bool,
  tenantAppId: string,
}

export default OnboardingSetupTenant
