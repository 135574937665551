import { useContext, useState } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { useFeatureFlag } from 'configcat-react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { node, number } from 'prop-types'
import GlobalContext from '../contexts/GlobalContext'
import 'react-toastify/dist/ReactToastify.css'
import DarkModeControl from '../components/DarkModeToggle/DarkModeControl'
import Alerts from '../components/alerts/Alerts'
import SSOAdmin from '../components/sso/SSOAdmin'
import SSOUsers from '../components/sso/SSOUsers'

const TabPanel = ({ children, value, index }) => (
  <div
    role='tabpanel'
    hidden={value !== index}
    id={`tbp-${index}`}
    aria-labelledby={`tb-${index}`}
    className='settings-section mt-4'
  >
    {value === index && children}
  </div>
)

TabPanel.propTypes = {
  children: node.isRequired,
  value: number.isRequired,
  index: number.isRequired,
}

const Settings = () => {
  const { loadingState, setLoadingState } = useContext(GlobalContext)
  const { value: enableDarkModeValue } = useFeatureFlag('enableDarkMode', false)

  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmNewPass, setConfirmNewPass] = useState('')
  const [passSuccess, setPassSuccess] = useState('')
  const [passError, setPassError] = useState([])

  const [tabIndex, setTabIndex] = useState(0)
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  const isClientAdmin = localStorage.getItem('isClientAdmin') === 'true'
  const isSSOUser = localStorage.getItem('isSSOUser') === 'true'
  const savedUserName = localStorage.getItem('username')

  const sessionUsername = () => {
    if (savedUserName === null) {
      toast.info('Your session has expired. Please log back in.')

      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    }
  }

  // Password conditions
  const validPass = () => {
    const issues = []

    if (newPass !== confirmNewPass) {
      issues.push('New password fields do not match')
    }
    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8 -25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/\s/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setPassError(issues)
      setPassSuccess('')
      return false
    }

    setPassError([])
    return true
  }

  const handlePassChange = async () => {
    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/change-user-pass`,
        {
          newPassword: newPass,
          Password: oldPass,
          Username: savedUserName,
        }
      )

      if (response.data.isError !== undefined) {
        const { isError } = response.data

        if (isError) {
          setPassError([response.data.message])
          setPassSuccess('')
        } else {
          setPassSuccess(response.data.message)
          setPassError([])
        }
      } else if (response.data === 'Password Set Successfully') {
        setPassSuccess('Password Set Successfully')
        setPassError([])
      } else if (
        response.data ===
        'password does not match, please ensure you have entered valid credentials.'
      ) {
        setPassError([
          'Existing password does not match, please ensure you have entered valid credentials.',
        ])
        setPassSuccess('')
      } else {
        setPassError(['An unexpected error has occurred.'])
        setPassSuccess('')
      }
    } catch (error) {
      if (error.response.status === 500) {
        setPassError([
          'Could not change password, please check the credentials you entered are valid',
        ])
        setPassSuccess('')
      }
    } finally {
      setLoadingState(false)
    }
  }

  // Run conditions and pw change request
  const handleSubmit = e => {
    e.preventDefault()
    sessionUsername()

    if (validPass()) {
      handlePassChange()
    }
  }

  const settingsTab = () => (
    <div className='settings-section'>
      {!isSSOUser && (
        <form onSubmit={handleSubmit} className='w-full max-w-lg mt-4s'>
          {passSuccess && (
            <Alerts title='Success' alert='success' margin='mb-6'>
              <p>{passSuccess}</p>
            </Alerts>
          )}

          {passError.length > 0 && (
            <Alerts title='Error' alert='error' margin='mb-6'>
              <ul>
                {passError.map(msg => (
                  <li key={msg} className='ml-4 list-disc'>
                    {msg}
                  </li>
                ))}
              </ul>
            </Alerts>
          )}

          <h4 className='mb-4'>Change your password</h4>
          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : (
            <>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='existingpassword'>Existing password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setOldPass(e.target.value)
                  }}
                />
              </div>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='newpassword'>New password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setNewPass(e.target.value)
                  }}
                />
              </div>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='confirmnewpassword'>Confirm new password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setConfirmNewPass(e.target.value)
                  }}
                />
              </div>
            </>
          )}

          <button className='btn cyan-btn mt-4' type='submit'>
            Change Password
          </button>
        </form>
      )}

      {isSSOUser && (
        <Alerts title='SSO User' alert='info' margin='mt-4'>
          <p>
            You are authenticated via SSO and you cannot change your password
            from here.
          </p>
        </Alerts>
      )}
    </div>
  )

  return (
    <div className='ui-panel my-account'>
      <div className='heading'>
        <h2>Settings</h2>
      </div>
      <div className='action-body no-progress-bar flex flex-col'>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label='Settings tabs'
        >
          <Tab label='Password' id='tb-0' />
          {isClientAdmin && <Tab label='Single Sign-On' id='tb-1' />}
          {isClientAdmin && <Tab label='User Management' id='tb-2' />}
          {enableDarkModeValue && <Tab label='Appearance' id='tb-3' />}
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          {settingsTab()}
        </TabPanel>

        {isClientAdmin && (
          <>
            <TabPanel value={tabIndex} index={1}>
              {isClientAdmin && <SSOAdmin />}
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {isClientAdmin && <SSOUsers />}
            </TabPanel>
          </>
        )}

        {enableDarkModeValue && (
          <TabPanel value={tabIndex} index={3}>
            <DarkModeControl />
          </TabPanel>
        )}
      </div>
    </div>
  )
}

export default Settings
