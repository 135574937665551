import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useState } from 'react'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { func } from 'prop-types'
import logo from '../assets/inforcer-logo.png'
import NotificationPanel from '../components/NotificationPanel'
import { useNotificationsContext } from '../contexts/NotificationsContext'

const Header = ({ handleLogout }) => {
  const [open, setOpen] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const { activeNotificationCount, notifications } = useNotificationsContext()

  // const location = useLocation();

  const isSalesAdmin = localStorage.getItem('isSalesAdmin')

  const handleClick = () => {
    setOpen(!open)
    document.body.classList.toggle('mobile-menu')
  }

  const openIcon = (
    <FontAwesomeIcon
      className='ml-3'
      icon={icon({ name: 'bars' })}
      onClick={handleClick}
    />
  )
  const closeIcon = (
    <FontAwesomeIcon
      className='ml-3'
      icon={icon({ name: 'xmark' })}
      onClick={handleClick}
    />
  )

  return (
    <>
      <div className='h-[64px]'>
        <header className='z-10 dark:bg-slate-800 dark:border-b-sky-900 h-[64px]'>
          <img
            src={logo}
            className='inforcer-logo mobile-inforcer-logo'
            style={{ display: 'none' }}
            alt='Inforcer Logo'
          />

          <div className='header-nav gap-3'>
            {isSalesAdmin === 'true' && (
              <Link
                to='/admin-portal'
                className='btn navy-btn dark:bg-sky-800 admin-portal-btn'
              >
                Admin
              </Link>
            )}
            <div className='relative pt-1'>
              <FontAwesomeIcon
                icon={faBell}
                className='fill-current dark:text-gray-100'
                onClick={() => setNotificationsOpen(!notificationsOpen)}
              />
              {activeNotificationCount > 0 && (
                <>
                  <div className='absolute -bottom-1 w-full'>
                    <div className='h-1 overflow-hidden'>
                      <div className='progress w-full h-full left-right' />
                    </div>
                  </div>
                  <div className='absolute notification-icon inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white rounded-full -top-2 -right-2'>
                    {activeNotificationCount}
                  </div>
                </>
              )}
            </div>

            <Link to='/settings' className='flex'>
              <FontAwesomeIcon
                icon={faGear}
                className='fill-current dark:text-gray-100'
              />
            </Link>
            <button
              type='button'
              onClick={handleLogout}
              className='font-semibold text-lg dark:text-gray-100'
            >
              Logout
            </button>
            {open ? closeIcon : openIcon}
          </div>
        </header>
      </div>
      <NotificationPanel
        open={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
        notifications={notifications}
      />
    </>
  )
}

Header.propTypes = {
  handleLogout: func.isRequired,
}

export default Header
