import { useContext, useEffect, useState } from 'react'
import Axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import GlobalContext from '../contexts/GlobalContext'
import logo from '../assets/inforcer-logo.png'

const UpdatePassword = () => {
  const {
    loadingState,
    setLoadingState,
    forcePasswordChange,
    setForcePasswordChange,
  } = useContext(GlobalContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmNewPass, setConfirmNewPass] = useState('')
  const [passSuccess, setPassSuccess] = useState('')
  const [passError, setPassError] = useState([])

  const savedUserName = localStorage.getItem('username')

  useEffect(() => {
    if (!forcePasswordChange && location.pathname === '/update-password') {
      navigate('/')
    }
  }, [forcePasswordChange, location.pathname, navigate])

  // Password conditions
  const validPass = () => {
    const issues = []

    if (newPass !== confirmNewPass) {
      issues.push('New password fields do not match')
    }
    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8 -25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/[\s]/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setPassError(issues)
      setPassSuccess('')
      return false
    }

    setPassError([])
    return true
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (validPass()) {
      handlePassChange()
    }
  }

  const handlePassChange = async () => {
    try {
      setLoadingState(true)

      const requestBody = {
        newPassword: newPass,
        Password: oldPass,
        Username: savedUserName,
      }

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/change-user-pass`,
        requestBody
      )

      if (response.data.isError !== undefined) {
        const { isError } = response.data

        if (isError) {
          setPassError([response.data.message])
          setPassSuccess('')
        } else {
          setPassSuccess(response.data.message)
          setPassError([])
          localStorage.removeItem('forcePasswordChange')
          setForcePasswordChange(false)
          navigate('/')
        }
      } else if (response.data === 'Password Set Successfully') {
        setPassSuccess('Password Set Successfully')
        setPassError([])
        localStorage.removeItem('forcePasswordChange')
        setForcePasswordChange(false)
        navigate('/')
      } else if (
        response.data ===
        'password does not match, please ensure you have entered valid credentials.'
      ) {
        setPassError([
          'Existing password does not match, please ensure you have entered valid credentials.',
        ])
        setPassSuccess('')
      } else if (
        response.data ===
        'Error, source password and new password are the same.'
      ) {
        setPassError(['Error, source password and new password are the same.'])
        setPassSuccess('')
      } else if (response.data.message !== undefined) {
        setPassError([response.data.message])
        setPassSuccess('')
      } else {
        setPassError(['An unexpected error has occurred.'])
        setPassSuccess('')
      }
    } catch (error) {
      if (error.response.status === 500) {
        setPassError([
          'Could not change password, please check the credentials you entered are valid',
        ])
        setPassSuccess('')
      }
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='login-page'>
      <div className='login-box text-center'>
        <form onSubmit={handleSubmit}>
          <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />

          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : (
            <div className='login-box-inner'>
              {passSuccess && (
                <div className='text-left'>
                  <span className='text-green-700 mb-[24px] list-disc'>
                    {passSuccess}
                  </span>
                </div>
              )}

              {passError.length > 0 && (
                <div className='text-left'>
                  <ul>
                    {passError.map((issue, index) => (
                      <li
                        key={index}
                        className='text-red-500 mb-[24px] list-disc ml-[20px]'
                      >
                        {issue}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <input
                type='password'
                placeholder='Existing Password'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                onChange={e => {
                  setOldPass(e.target.value)
                }}
              />

              <input
                type='password'
                placeholder='New Password'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                onChange={e => {
                  setNewPass(e.target.value)
                }}
              />

              <input
                type='password'
                placeholder='Confirm New Password'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                onChange={e => {
                  setConfirmNewPass(e.target.value)
                }}
              />

              <button className='btn cyan-btn' type='submit'>
                Update Password
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default UpdatePassword
