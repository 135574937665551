import { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import SearchBar from './SearchBar'
import useGetClients from '../api/useGetClients'
import Pagination from './Pagination'
import SharedBaselinesSkeleton from './SharedBaselineGroupSkeleton'

const ClientsSelect = () => {
  const {
    setValue,
    control,
    formState: { errors },
    resetField,
  } = useFormContext()

  const { data: clients = [], error, isError, isLoading } = useGetClients()

  const searchTerm = useWatch({
    control,
    name: 'searchClients',
    defaultValue: '',
  })

  const isEdit = useWatch({
    control,
    name: 'isEdit',
  })

  const selectedClientId = useWatch({
    control,
    name: 'clientId',
  })

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  useEffect(() => {
    setCurrentPage(1) // Reset to first page when search term changes
  }, [searchTerm])

  const filteredClients = clients.filter(client =>
    client.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const paginatedClients = isEdit
    ? filteredClients.filter(client => client.clientId === selectedClientId)
    : filteredClients.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )

  const handleSelectClient = event => {
    setValue('clientId', event.target.value)
    resetField('publishedBaselines')
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  if (isLoading) {
    return (
      <div className='p-4 border mt-2'>
        <SharedBaselinesSkeleton loadingTitle='Loading clients...' />
      </div>
    )
  }

  if (isError) {
    return (
      <div className='p-4 border mt-2'>
        <div className='mt-3'>
          <p>{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='font-semibold'>
        {isEdit ? 'Client selected' : 'Select client'}
      </div>
      <div
        className={`p-4 border mt-2 ${
          errors.clientId
            ? 'border-red-500'
            : 'focus:ring-blue-500 focus:border-blue-500'
        }`}
      >
        {!isEdit && <SearchBar fieldName='searchClients' />}

        <div className='mt-2'>
          {paginatedClients.map(client => (
            <div key={client.clientId} className='flex items-center mb-2'>
              <Controller
                control={control}
                name='clientId'
                rules={{ required: 'Client is required' }}
                render={({ field }) => (
                  <input
                    type='radio'
                    id={`client-${client.clientId}`}
                    value={client.clientId}
                    onChange={e => {
                      handleSelectClient(e)
                    }}
                    className='mr-2'
                    disabled={isEdit}
                    checked={
                      isEdit
                        ? selectedClientId
                        : field.value === String(client.clientId)
                    }
                  />
                )}
              />

              <label
                className='font-normal'
                htmlFor={`client-${client.clientId}`}
              >
                {client.displayName}
              </label>
            </div>
          ))}
        </div>
        {!isEdit && (
          <Pagination
            totalItems={filteredClients.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  )
}

export default ClientsSelect
