import { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { v4 as uuidv4 } from 'uuid'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import TargetTenants from '../components/TargetTenants'
import ListClients from '../components/ListClients'
import SearchBar from '../components/SearchBar'

const AllTenants = () => {
  const [loadingState, setLoadingState] = useState(true)

  // Form Logic
  const formArray = [1, 2]
  const [formNo, setFormNo] = useState(formArray[0])

  // Target tenants
  const [targetTenants, setTargetTenants] = useState([])
  const [tenantSearch, setTenantSearch] = useState('')
  const [allTenants, setAllTenants] = useState([])

  // Clients list
  const [clients, setClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [allClients, setAllClients] = useState([])
  const [clientSearch, setClientSearch] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
        )
        const { data } = response
        setAllClients(data)
        setClients(data)
      } catch (error) {
        console.error('Error:', error.response.status)

        if (error.response.status === 401) {
          toast.info('Your session has expired. Please log back in.')

          setTimeout(() => {
            window.location.href = '/'
          }, 2000)
        }
      } finally {
        setLoadingState(false)
      }
    }
    fetchClients()
  }, [])

  const filterClients = searchValue => {
    if (searchValue) {
      // If the search query is provided perform local filtering
      const filteredClients = allClients.filter(client =>
        client.displayName.toLowerCase().includes(searchValue.toLowerCase())
      )
      setClients(filteredClients)
    } else {
      // else fetch all tenants from the API
      setClients(allClients)
    }
  }

  const fetchTenants = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants-by-client`,
        { clientId: selectedClient.clientId }
      )
      const data = response.data.Data
      setAllTenants(data)
      setTargetTenants(data)
    } catch (error) {
      console.error('Error:', error.response.status)

      if (error.response.status === 401) {
        toast.info('Your session has expired. Please log back in.')

        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }
    } finally {
      setLoadingState(false)
    }
  }

  const filterTenants = searchValue => {
    if (searchValue) {
      // If the search query is provided perform local filtering
      const filteredTenants = allTenants.filter(tenant =>
        tenant.tenantFriendlyName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
      setTargetTenants(filteredTenants)
    } else {
      // else fetch all tenants from the API
      setTargetTenants(allTenants)
    }
  }

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (!selectedClient) {
        toast.error('Please select a client')
        return
      }
      fetchTenants()
      setFormNo(formNo + 1)
    }
  }

  const pre = () => {
    if (formNo === 2) {
      setTargetTenants('')
    }
    setFormNo(formNo - 1)
  }

  // Timeline step text
  const stepH1 = 'Select Clients'
  const stepH2 = 'Select Tenants'

  const stepP1 = 'Select Clients'
  const stepP2 = 'Select Tenants'

  return (
    <div className='ui-panel all-tenants'>
      <ToastContainer />
      <div className='heading'>
        <h2>All Tenants</h2>
        <p>Select a client to view the corresponding tenants</p>
      </div>
      <div className='action-body'>
        <div className='progress-bar'>
          {formArray.map((v, i) => (
            <div key={uuidv4()}>
              <div className='flex'>
                <div className='flex'>
                  <div
                    className={`progress-number progress-number-${v} ${formNo - 1 === i || formNo - 1 === i + 1 || formNo - 1 === i + 2 || formNo === formArray.length ? 'cyan-bg text-white' : 'bg-slate-300'}`}
                  >
                    {v}
                  </div>
                  <div className='progress-text'>
                    <p className='navy-text'>
                      <strong>
                        {(v === 1 && stepH1) || (v === 2 && stepH2)}
                      </strong>
                    </p>
                    <p className='text-gray-400'>
                      {(v === 1 && stepP1) || (v === 2 && stepP2)}
                    </p>
                  </div>
                </div>
              </div>
              {i !== formArray.length - 1 && (
                <div className='progress-bar-link' />
              )}
            </div>
          ))}
        </div>
        <div className='action-body-form'>
          {formNo === 1 && (
            <>
              {loadingState ? (
                <div id='loading-spinner'>
                  <div className='loading' />
                </div>
              ) : (
                <>
                  {selectedClient ? (
                    <div className='select-tenant-heading'>
                      <h4>Selected client: </h4>
                      <p className='pl-1'>{selectedClient.displayName}</p>
                    </div>
                  ) : (
                    <div className='select-tenant-heading'>
                      <h4>Select Client</h4>
                      <p>&nbsp;</p>
                    </div>
                  )}
                  <SearchBar
                    searchValue={clientSearch}
                    onSearchChange={newSearchValue => {
                      setClientSearch(newSearchValue)
                      filterClients(newSearchValue)
                    }}
                    onClear={() => {
                      setClientSearch('')
                      filterClients('')
                      setSelectedClient(null)
                    }}
                  />
                  <ListClients
                    clients={clients}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    clientSearch={clientSearch}
                    onClientSearchChange={newSearchValue => {
                      setClientSearch(newSearchValue)
                      filterClients(newSearchValue)
                    }}
                  />
                </>
              )}

              <div className='mt-6 gap-3 flex'>
                <button onClick={() => navigate(-1)} className='btn navy-btn'>
                  <span>Back</span>
                </button>
                <button onClick={next} className='btn cyan-btn'>
                  Next
                </button>
              </div>
            </>
          )}
          {formNo === 2 && (
            <>
              {selectedClient ? (
                <div className='select-tenant-heading mb-2'>
                  <h4>Selected client: </h4>
                  <p className='pl-1'>{selectedClient.displayName}</p>
                </div>
              ) : (
                <div className='select-tenant-heading mb-2'>
                  <h4>Select Client</h4>
                  <p>&nbsp;</p>
                </div>
              )}
              <div className='select-tenant-heading'>
                <h4>Search Tenants</h4>
              </div>
              <SearchBar
                onSearchChange={newSearchValue => {
                  setTenantSearch(newSearchValue)
                  filterTenants(newSearchValue)
                }}
                searchValue={tenantSearch}
              />
              <TargetTenants
                targetTenants={targetTenants}
                tenantSearchValue={tenantSearch}
                onTenantSearchChange={newSearchValue => {
                  setTenantSearch(newSearchValue)
                  filterTenants(newSearchValue)
                }}
              />

              <div className='mt-6 gap-3 flex'>
                <button onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default AllTenants
