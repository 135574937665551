import { string } from 'prop-types'
import { ReactComponent as MemberIcon } from '../assets/member-ellipse.svg'
import { ReactComponent as SubscriberIcon } from '../assets/subscriber-ellipse.svg'
import { ReactComponent as InforcerShield } from '../assets/inforcer-shield-ellipse.svg'
import { ReactComponent as SharedBaseline } from '../assets/shared-baseline.svg'

const Tag = ({ type }) => {
  switch (type) {
    case 'subscriber':
      return (
        <div className='tag subscriber-tag'>
          <SubscriberIcon width={22} height={22} />
          <span>Subscriber</span>
        </div>
      )
    case 'shared':
      return (
        <div className='tag shared-baselines-tag'>
          <SharedBaseline width={22} height={22} />
          <span>Shared Baseline</span>
        </div>
      )
    case 'baseline':
      return (
        <div className='tag member-tag'>
          <SharedBaseline width={22} height={22} />
          <span>Baseline</span>
        </div>
      )
    case 'member':
      return (
        <div className='tag member-tag'>
          <MemberIcon width={22} height={22} />
          <span>Member</span>
        </div>
      )
    case 'inforcerManaged':
      return (
        <div className='tag inforcer-managed-tag'>
          <InforcerShield width={22} height={22} />
          <span>Inforcer Managed</span>
        </div>
      )
    default:
      return null
  }
}

export default Tag

Tag.propTypes = {
  type: string.isRequired,
}
