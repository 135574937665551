import TagsCell from './ListTables/TagsCell'

const BackupTagsCell = ({ row }) => {
  const tags = []

  if (row.original.isGoldenTenant || row.original.isBaseline) {
    tags.push({ styles: 'baseline-tag', text: 'Baseline' })
  }

  if (row.original.isSharedBaseline) {
    tags.push({ styles: 'shared-tag', text: 'Shared Baseline' })
  }

  if (row.original.inforcerManaged) {
    tags.push({ styles: 'inforcer-table-tag', text: 'Inforcer Managed' })
  }

  return <TagsCell tags={tags} />
}

export default BackupTagsCell
