import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable } from 'material-react-table'
import { defaultCellStyle, defaultTableProperties } from './config'
import './ListTable.css'

const ListTable = ({
  config,
  setSelectedListItems,
  selectedListItems,
  enableMultiRowSelection,
  uniqueKey,
  loading,
  enableRowSelection,
}) => {
  const { columns, data } = config
  const initialSelection = selectedListItems.reduce(
    (acc, selectedItem) => ({ ...acc, [selectedItem[uniqueKey]]: true }),
    {}
  )
  const [rowSelectionState, setRowSelectionState] = useState(initialSelection)

  // to abstract away the implementation details of the table row selection management we
  // allow the user to pass in an array of entries from the data and we convert that into an
  // array that the table can use to handle the management state
  useEffect(() => {
    if (rowSelectionState) {
      const filteredData = data?.filter(
        entry => !!rowSelectionState[entry[uniqueKey]]
      )
      setSelectedListItems(filteredData)
    }
  }, [data, rowSelectionState, setSelectedListItems, uniqueKey])

  // this is where we take the selected list items (e.g. the data entries) and convert
  // them into the object entries to set the respective rows in the table
  const selectedRows = useMemo(
    () =>
      selectedListItems.reduce(
        (acc, selectedItem) => ({ ...acc, [selectedItem[uniqueKey]]: true }),
        {}
      ),
    [selectedListItems, uniqueKey]
  )

  const tableColumnDefinitions = useMemo(
    () =>
      columns.map(col => ({
        header: col.header,
        accessorKey: col.accessor,
        muiTableBodyCellProps: {
          sx: col.sx || defaultCellStyle,
        },
        muiTableHeadCellProps: {
          sx: col.sx || defaultCellStyle,
        },
        size: 100,
        Cell: col.cell,
      })),
    [columns]
  )

  return (
    <MaterialReactTable
      columns={tableColumnDefinitions}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...defaultTableProperties}
      getRowId={row => row[uniqueKey]}
      data={data}
      enableRowSelection={enableRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      onRowSelectionChange={setRowSelectionState}
      state={{ rowSelection: selectedRows, isLoading: loading }}
    />
  )
}

ListTable.defaultProps = {
  loading: false,
  enableRowSelection: true,
}

ListTable.propTypes = {
  config: shape({
    columns: arrayOf(
      shape({
        header: string.isRequired,
        accessor: string.isRequired,
        cell: func,
      })
    ).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: arrayOf(object).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedListItems: arrayOf(object).isRequired,
  uniqueKey: string.isRequired,
  setSelectedListItems: func.isRequired,
  enableMultiRowSelection: bool.isRequired,
  loading: bool,
  enableRowSelection: oneOfType([bool, func]),
}

export default ListTable
