import { useEffect, useState } from 'react'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import ListTable from '../../ListTables/ListTable'

/**
 * This wrapper table is necessary due to the way the ListTable component is structured.
 * ListTable will abstract away the implementation details of the table row selection management,
 * as a result if you pass in an anonymous function as the onRowSelectionChange prop it will cause
 * the ListTable component to re-render infinitely. In this instance we needed to create an object
 * higher up with the details of subscribers for each table so we had to create an anonymous
 * function in that way.
 *
 * @param onChange function that gets called with the baselineTenantId and the selected members
 * @param publishedBaselineTenantId the baselineTenantId for the current table
 * @param columns the columns for the table
 * @param data the data for the table (in this case members of the shared baseline group)
 * @param uniqueKey the unique key for the table
 * @param loading boolean to indicate if the table is loading
 * @param initialSubscribers the initial subscribers for the publishedBaselineTenant
 * @returns {JSX.Element}
 * @constructor
 */
const SubscribersTable = ({
  onChange,
  publishedBaselineTenantId,
  columns,
  data,
  uniqueKey,
  loading,
  initialSubscribers,
}) => {
  const [selectedMembers, setSelectedMembers] = useState(initialSubscribers)

  useEffect(() => {
    onChange(prev => ({
      ...prev,
      [publishedBaselineTenantId]: selectedMembers,
    }))
  }, [onChange, publishedBaselineTenantId, selectedMembers])

  return (
    <ListTable
      config={{ columns, data }}
      selectedListItems={selectedMembers}
      setSelectedListItems={setSelectedMembers}
      uniqueKey={uniqueKey}
      enableMultiRowSelection
      loading={loading}
    />
  )
}

SubscribersTable.propTypes = {
  columns: arrayOf(
    shape({
      header: string.isRequired,
      accessor: string.isRequired,
      cell: func,
    })
  ).isRequired,
  data: arrayOf(
    shape({
      memberClientDisplayName: string.isRequired,
      memberClientId: number.isRequired,
    })
  ).isRequired,
  initialSubscribers: arrayOf(
    shape({
      memberClientDisplayName: string.isRequired,
      memberClientId: number.isRequired,
      publishedBaselineTenantId: number.isRequired,
      publishedBaselineTenantDisplayName: string.isRequired,
    })
  ).isRequired,
  uniqueKey: string.isRequired,
  onChange: func.isRequired,
  loading: bool.isRequired,
  publishedBaselineTenantId: number.isRequired,
}

export default SubscribersTable
