import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLock,
  faMagnifyingGlass,
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import { UNLICENSED_POLICY } from '../utils/Policy'

import Tooltip from './tooltip/Tooltip'
import IsReadOnlyPolicy from '../utils/IsReadOnlyPolicy'

const PolicyPicker = ({
  data,
  checkedItems,
  showChildren,
  selectAll,
  handleParentCheckboxChange,
  handleChildCheckboxChange,
  handleSelectAll,
  handleDropdownToggle,
  selectedTargetTenant,
}) => {
  if (!data || Object.keys(data).length === 0) {
    return <h4 className='my-[30px]'>No policies available</h4>
  }

  const handleViewClick = async (policyId, parentName, childName) => {
    const fePolicy = {
      clientTenantId: selectedTargetTenant.clientTenantId,
      policyTypeId: policyId.PolicyTypeId,
      policyGuid: policyId.id,
      target: '365',
    }

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policy-data`,
        { params: fePolicy }
      )

      const policyData = Array.isArray(response.data)
        ? response.data[0]
        : response.data

      window.policyData = JSON.stringify(policyData)
      window.policyTypeName = JSON.stringify(parentName)
      window.policyDisplayName = JSON.stringify(childName)
      window.tenantName = JSON.stringify(
        selectedTargetTenant.tenantFriendlyName
      )

      window.open(
        '/policy.html',
        `policy-viewer-${policyData.id}`,
        'width=600,height=900'
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div style={{ margin: '0 0 20px 0' }} />
      <div className='nested-checkboxes'>
        <div className='select-all-container'>
          <label>
            <input
              type='checkbox'
              checked={selectAll}
              onChange={handleSelectAll}
            />
            &nbsp;&nbsp;Select all
          </label>
        </div>
        {Object.entries(data).map(([parentType, children]) => {
          const isUnlicensed =
            children?.length > 0 && children[0].id === UNLICENSED_POLICY
          return (
            <div key={parentType} className='parent-checkbox-container'>
              <div className='parent-checkbox'>
                <div
                  className={`parent-check-label ${isUnlicensed ? 'opacity-60' : ''}`}
                >
                  <input
                    type='checkbox'
                    disabled={isUnlicensed}
                    name={parentType}
                    checked={!!checkedItems[parentType]}
                    onChange={handleParentCheckboxChange}
                  />
                  &nbsp;&nbsp;{parentType}
                </div>
                <button
                  type='button'
                  aria-label='toggle'
                  onClick={() => handleDropdownToggle(parentType)}
                >
                  {showChildren[parentType] ? (
                    <FontAwesomeIcon icon={faMinusCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  )}
                </button>
              </div>
              {showChildren[parentType] && (
                <ul
                  className={`checkbox-children ${isUnlicensed ? 'pointer-events-none opacity-60' : ''}`}
                >
                  {Array.isArray(children) && children.length > 0 ? (
                    children.map(child => (
                      <li key={child.id}>
                        {IsReadOnlyPolicy(child) ? (
                          <span className='lock' aria-label='Padlock'>
                            <Tooltip content='Read Only'>
                              <FontAwesomeIcon icon={faLock} />
                            </Tooltip>
                          </span>
                        ) : (
                          <input
                            aria-label='Child policy selected toggle'
                            id={`selectedChildPolicy-${child.id}`}
                            type='checkbox'
                            disabled={isUnlicensed}
                            name={child.id}
                            checked={checkedItems[child.id] || false}
                            onChange={handleChildCheckboxChange}
                          />
                        )}
                        <span
                          className={`${IsReadOnlyPolicy(child) ? 'opacity-70 pr-3' : ''} policy-name`}
                        >
                          {child.displayName || child.name}
                        </span>

                        <div className='policy-explorer-btns flex items-center'>
                          <div className='flex items-center'>
                            {!isUnlicensed && (
                              <button
                                type='button'
                                aria-label='View policy JSON'
                                className='btn'
                                onClick={() =>
                                  handleViewClick(
                                    child,
                                    parentType,
                                    child.displayName || child.name
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                              </button>
                            )}
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No children found.</li>
                  )}
                </ul>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PolicyPicker
