import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightLong,
  faCheckCircle,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import { arrayOf, func, object, oneOf, string } from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import ModalActionButtons from '../Modal/ModalActionButtons'
import deployPolicySummaryClipboardText from './copyToClipboard'
import ReviewSummaryContent from './ReviewSummaryContent'
import DeployPolicyDialogHeader from './DeployPolicyDialogHeader'
import { steps, tenantShape } from '../TenantAlignment/helpers'
import HorizontalDivider from '../HorizontalDivider'

const DeployPolicyFinalSummary = ({
  successfulPolicies,
  erroredPolicies,
  additionalPolicySettings,
  onClose,
  sourceTenantName,
  destinationTenants,
  contentAlignment,
}) => {
  const destinationTenantNames = destinationTenants.map(
    tenant => tenant.tenantFriendlyName
  )
  const totalPolicies = successfulPolicies.length + erroredPolicies.length

  // TODO - look if we can replace the UUID's with something more meaningful
  return (
    <div className='flex flex-col gap-4'>
      <DeployPolicyDialogHeader
        step={steps.final}
        alignment={contentAlignment}
      />
      <div
        className={`flex flex-row gap-8 ${contentAlignment === 'center' ? 'self-center' : ''}`}
      >
        <div className='text-center'>
          <h4 className='text-lg'>
            <strong>Source tenant </strong>
          </h4>
          <p>{sourceTenantName}</p>
        </div>
        <FontAwesomeIcon
          icon={faArrowRightLong}
          size='xl'
          className='self-center'
        />
        <div className='text-center pb-4'>
          <h4 className='text-lg'>
            <strong>Destination tenants </strong>
          </h4>
          {destinationTenants.map(tenant => (
            <p key={tenant.clientTenantId}>{tenant.tenantFriendlyName}</p>
          ))}
        </div>
      </div>

      {successfulPolicies?.length > 0 && (
        <div>
          <h4 className='text-lg mb-1'>
            <strong>Policies successfully queued for deployment - </strong>
            {successfulPolicies.length}/{totalPolicies}
            <HorizontalDivider />
          </h4>
          <div className='flex flex-col gap-2'>
            {successfulPolicies.map(item => (
              <div
                key={item.policyId}
                className='flex flex-row justify-between'
              >
                {item}
                <FontAwesomeIcon icon={faCheckCircle} size='lg' />
              </div>
            ))}
          </div>
        </div>
      )}

      {erroredPolicies.length > 0 && (
        <>
          <h4>
            <strong>Policies with deployment errors - </strong>
            {erroredPolicies.length}/{totalPolicies}
          </h4>
          <HorizontalDivider />
          <div className='flex flex-col gap-2'>
            {erroredPolicies.map(({ displayName }) => (
              <div key={uuidv4()} className='flex flex-row justify-between'>
                {displayName}
                <FontAwesomeIcon icon={faCircleXmark} size='lg' />
              </div>
            ))}
          </div>
        </>
      )}

      <ReviewSummaryContent
        additionalPolicySettings={additionalPolicySettings}
      />

      <ModalActionButtons
        onCancel={() =>
          navigator.clipboard.writeText(
            deployPolicySummaryClipboardText(
              additionalPolicySettings,
              successfulPolicies,
              erroredPolicies,
              sourceTenantName,
              destinationTenantNames
            )
          )
        }
        onConfirm={onClose}
        cancelActionText='Copy to Clipboard'
        confirmActionText='Close'
      />
    </div>
  )
}

DeployPolicyFinalSummary.defaultProps = {
  contentAlignment: 'center',
}

DeployPolicyFinalSummary.propTypes = {
  successfulPolicies: arrayOf(string).isRequired,
  erroredPolicies: arrayOf(string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalPolicySettings: arrayOf(object).isRequired,
  sourceTenantName: string.isRequired,
  destinationTenants: arrayOf(tenantShape.isRequired).isRequired,
  onClose: func.isRequired,
  contentAlignment: oneOf(['center', 'left']),
}

export default DeployPolicyFinalSummary
