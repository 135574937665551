import { string, bool, func } from 'prop-types'
import { Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchBar = ({
  fieldName,
  type,
  isAllSelected,
  toggleSelectAll,
  disabled,
}) => (
  <div
    className={`flex items-center border border-gray-300 mb-4 p-2 pl-3 pr-3 ${disabled && 'bg-gray-100'}`}
  >
    <FontAwesomeIcon icon={faSearch} className='mr-2' />
    <Controller
      name={fieldName}
      defaultValue=''
      render={({ field }) => (
        <input
          type='text'
          placeholder='Search'
          className='p-2 flex-grow focus:outline-none'
          {...field}
          disabled={disabled}
        />
      )}
    />
    {type === 'checkbox' && (
      <button
        type='button'
        className='btn navy-btn ml-2'
        onClick={toggleSelectAll}
        disabled={disabled}
      >
        {isAllSelected ? 'Deselect All' : 'Select All'}
      </button>
    )}
  </div>
)

export default SearchBar

SearchBar.defaultProps = {
  type: 'text',
  isAllSelected: false,
  toggleSelectAll: () => {},
  disabled: false,
}

SearchBar.propTypes = {
  fieldName: string.isRequired,
  type: string,
  isAllSelected: bool,
  toggleSelectAll: func,
  disabled: bool,
}
