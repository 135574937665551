import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'

import SharedBaselineGroupForm from '../../components/SharedBaselines/components/SharedBaselineGroupForm'
import useGetBaselineGroups from '../../components/SharedBaselines/api/useGetBaselineGroups'
import useDeleteBaselineGroup from '../../components/SharedBaselines/api/admin/useDeleteBaselineGroup'
import CustomerBaselineCard from '../../components/SharedBaselines/components/CustomerBaselineCard'

import Modal from '../../components/Modal/Modal'
import ModalActionButtons from '../../components/Modal/ModalActionButtons'
import SearchBar from '../../components/SharedBaselines/components/BaselinesPageSearchBar'
import Tag from '../../components/SharedBaselines/components/Tag'
import Alerts from '../../components/alerts/Alerts'
import SharedBaselinesGroupSkeleton from '../../components/SharedBaselines/components/SharedBaselinesGroupSkeleton'

const SharedBaselinesManage = () => {
  const [isOpen, setIsOpen] = useState('')
  const [isEdit, setEdit] = useState(false)
  const [isDelete, setDelete] = useState(null)

  const [baselineGroupId, setBaselineGroupId] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')

  const itemsPerPage = 100

  const navigate = useNavigate()

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  const handleEdit = id => {
    setBaselineGroupId(id)
    setEdit(true)
    setIsOpen(prev => (prev === id ? null : id))
  }

  const handleCreate = () => {
    setEdit(false)
    setBaselineGroupId(null)
    setIsOpen(prev => (prev === 'create' ? '' : 'create'))
  }

  const {
    data: baselineGroups,
    isLoading: isLoadingBaselineGroups,
    isError: isErrorBaselineGroups,
    error: errorBaselineGroups,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetBaselineGroups(itemsPerPage, true)

  const filteredData = baselineGroups?.pages.flatMap(baselineGroup =>
    baselineGroup.data.filter(baseline =>
      baseline.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  )

  const deleteBaselineGroup = useDeleteBaselineGroup(() => {
    toast.success('Baseline group deleted successfully')
  })

  if (isLoadingBaselineGroups) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <SharedBaselinesGroupSkeleton />
        </div>
      </div>
    )
  }

  if (isErrorBaselineGroups) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <Alerts
            alert='error'
            title={errorBaselineGroups.response.data.message}
            margin='mb-4'
          >
            {errorBaselineGroups.response.data.errors?.map(error => (
              <ul key={error}>
                <li>{error}</li>
              </ul>
            ))}
          </Alerts>
        </div>
      </div>
    )
  }

  return (
    <div className='shared-baselines-page md:container mx-auto'>
      <div className='flex items-center gap-6 max-[1280px]:flex-wrap shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
        <div className='shared-baselines-heading w-full rounded-xl overflow-hidden'>
          <h2>Manage Policy Library</h2>
        </div>
        <div className='searchbar w-full rounded-xl overflow-hidden p-2'>
          <SearchBar
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleClearSearch={handleClearSearch}
          />
        </div>
      </div>

      <SharedBaselineGroupForm
        baselineGroupId={baselineGroupId}
        isEdit={isEdit}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <CustomerBaselineCard
        key='create'
        data={{ name: 'Shared baseline group' }}
      >
        <button
          type='button'
          onClick={handleCreate}
          className='btn cyan-btn mr-3'
        >
          Create
        </button>
      </CustomerBaselineCard>

      <h4 className='m-3'>Existing shared baseline groups</h4>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
        {filteredData.map(baselineGroup => (
          <CustomerBaselineCard
            key={baselineGroup.id}
            baselineData={baselineGroup}
            tags={[
              baselineGroup.inforcerManaged && (
                <Tag type='inforcerManaged' key='inforcerManaged-tag' />
              ),
            ]}
          >
            <div className='flex items-center mt-2'>
              <FontAwesomeIcon icon={faUser} className='mr-2' />
              <button
                type='button'
                className='underline'
                onClick={() =>
                  navigate(`/manage-subscribers/${baselineGroup.id}`)
                }
              >
                Manage Subscribers
              </button>
            </div>
            <div className='flex items-center'>
              <FontAwesomeIcon icon={faEdit} className='mr-2' />
              <button
                className='underline'
                type='button'
                aria-label='Delete Group Button'
                onClick={() => handleEdit(baselineGroup.id)}
              >
                Edit
              </button>
            </div>
            <div className='flex items-center'>
              <FontAwesomeIcon icon={faTrash} className='mr-2' />
              <button
                className='underline'
                type='button'
                aria-label='Delete Group Button'
                onClick={() => setDelete(baselineGroup.id)}
              >
                Delete
              </button>
            </div>

            <Modal
              isOpen={isDelete === baselineGroup.id}
              setModalOpen={() => setDelete(baselineGroup.id)}
            >
              <h2 className='text-xl font-bold mb-4'>
                Are you sure you want to delete "{baselineGroup.name}" baseline
                group?
              </h2>
              <ModalActionButtons
                onCancel={() => {
                  setDelete(null)
                }}
                onConfirm={() => {
                  deleteBaselineGroup.mutate({
                    baselineGroupId: baselineGroup.id,
                  })
                  setDelete(null)
                }}
                cancelActionText='Cancel'
                confirmActionText='Confirm'
              />
            </Modal>
          </CustomerBaselineCard>
        ))}
        {!filteredData.length && (
          <CustomerBaselineCard data={{}}>
            <p>No baselines found</p>
          </CustomerBaselineCard>
        )}
      </div>
      <div className='flex justify-center'>
        {hasNextPage && (
          <button
            className='mt-6 navy-btn btn'
            type='button'
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage && 'Loading more...'}
            {hasNextPage && 'Load More'}
          </button>
        )}
        {!hasNextPage && (
          <h4 className='pt-4 text-lg opacity-80'>All Groups Loaded</h4>
        )}
      </div>
      <div>{isFetching && !isFetchingNextPage ? 'Fetching...' : null}</div>
    </div>
  )
}

export default SharedBaselinesManage
