import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'

const RemoveOBCode = () => {
  const [clients, setClients] = useState([])
  const [obList, setObList] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const [selectedOBCode, setSelectedOBCode] = useState('')
  const [selectedClientId, setSelectedClientId] = useState('')
  const [requestError, setRequestError] = useState('')
  const [responseData, setResponseData] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    clientSearch()
  }, [])

  const clientSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response
      setClients(data)
    } catch (error) {
      console.error('Error:', error.response.status)

      if (error.response.status === 401) {
        toast.info('Your session has expired. Please log back in.')

        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }
    }
  }

  const handleClientChange = e => {
    setSelectedOBCode('')
    setObList([])
    const clientId = e.target.value
    setSelectedClientId(clientId)
    if (clientId) {
      obSearch(clientId)
    } else {
      setObList([])
    }
  }

  const obSearch = async clientId => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/listOnboardingTokens`,
        { clientId }
      )
      let { data } = response
      if (!Array.isArray(data)) {
        data = [data]
      }
      setObList(data)
    } catch (error) {
      console.error('Error:', error.response.status)
    }
  }

  const handleUserChange = e => {
    setSelectedOBCode(e.target.value)
  }

  const submitData = async () => {
    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/deleteOnboardingToken`,
        {
          clientId: selectedClientId,
          onboardingCodeId: selectedOBCode,
        }
      )

      setResponseData(response.data)
      setObList([])
      setSelectedOBCode('')
      setSelectedClientId('')
    } catch (error) {
      setRequestError('Error. Please check the details and try again.')
      console.error(error)
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Remove Onboarding Code</h2>
      </div>
      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>
      <div className='action-body no-progress-bar flex'>
        <form className='w-[50%] max-w-lg'>
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectclient'>
              Select Client:
            </label>
            <select
              value={selectedClientId}
              onChange={handleClientChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a client</option>
              {clients.map(client => (
                <option key={uuidv4()} value={client.clientId}>
                  {client.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectonboardingcode'>
              Select Onboarding Code:
            </label>
            <select
              value={selectedOBCode}
              onChange={handleUserChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select Onboarding Code</option>
              {obList.map(item => (
                <option key={uuidv4()} value={item.onboardingCodeId}>
                  {item.onboardingCode}
                </option>
              ))}
            </select>
          </div>

          <button
            type='button'
            disabled={!selectedOBCode}
            onClick={() => submitData()}
            className='btn cyan-btn mt-4'
          >
            Remove Onboarding Code
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4'>
          <h4 className='mb-[10px]'>Result:</h4>
          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : responseData ? (
            <p>{responseData}</p>
          ) : requestError ? (
            <p>{requestError}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default RemoveOBCode
