import {
  faCheckCircle,
  faChevronLeft,
  faSave,
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'

/**
 * @param {{
 *  baseline: any;
 *  tenants: any[];
 *  onClose: () => void;
 *  onSave: (name: any, alignedThreshold: number, semiAlignmentThreshold: number, baselineClientTenantId: any, clientTenantIds: any, id?: null, onSaved?: any) => void;
 * }} props
 */
const BaselineEditor = ({ baseline, tenants, loading, onClose, onSave }) => {
  const [name, setName] = useState(baseline.name ?? '')

  const [alignmentThreshold, setAlignmentThreshold] = useState(
    baseline.alignedThreshold ?? 100
  )

  const [semiAlignmentThreshold, setSemiAlignmentThreshold] = useState(
    baseline.semiAlignedThreshold ?? 90
  )

  const [baselineSearch, setBaselineSearch] = useState('')

  const [tenantSearch, setTenantSearch] = useState('')

  const [baselineTenantId, setBaselineTenantId] = useState(
    baseline.baselineClientTenantId
  )

  const [selectedTenants, setSelectedTenants] = useState(
    baseline.clientTenantIds ?? []
  )

  const [disabledTenants, setDisabledTenants] = useState([])

  const validAlignmentThresholds = useMemo(() => {
    if (alignmentThreshold < 0 || alignmentThreshold > 100) return false

    if (
      semiAlignmentThreshold > alignmentThreshold ||
      semiAlignmentThreshold < 0
    )
      return false

    return true
  }, [alignmentThreshold, semiAlignmentThreshold])

  const filteredTenantBaselines = useMemo(() => {
    const output = tenants?.filter(
      e =>
        (e.isBaseline || e.isSharedBaseline) &&
        e.tenantFriendlyName.toLowerCase().includes(baselineSearch)
    )
    output?.sort((a, b) =>
      a.tenantFriendlyName.localeCompare(b.tenantFriendlyName)
    )
    return output
  }, [baselineSearch, tenants])

  const filteredTenants = useMemo(() => {
    const output = tenants?.filter(
      e =>
        !e.isSharedBaseline &&
        e.tenantFriendlyName.toLowerCase().includes(tenantSearch)
    )
    output?.sort((a, b) =>
      a.tenantFriendlyName.localeCompare(b.tenantFriendlyName)
    )
    return output
  }, [tenantSearch, tenants])

  const saveEnabled =
    !!baselineTenantId && !!name.trim() && selectedTenants.length > 0

  const handleSave = () => {
    onSave(
      name,
      alignmentThreshold,
      semiAlignmentThreshold,
      baselineTenantId,
      selectedTenants,
      baseline.clientTenantGroupId,
      onClose
    )
  }

  useEffect(() => {
    const selectedBaselineTenant = tenants.find(
      baselineTenant => baselineTenant.clientTenantId === baselineTenantId
    )
    if (
      selectedBaselineTenant &&
      selectedBaselineTenant.isSharedBaseline === false
    ) {
      const disabled = filteredTenants
        .filter(tenant => tenant.isBaseline)
        .map(tenant => tenant.clientTenantId)
      setDisabledTenants(disabled)
      setSelectedTenants(selectedTenants =>
        selectedTenants.filter(id => !disabled.includes(id))
      )
    } else {
      setDisabledTenants([])
    }
  }, [baselineTenantId, tenants, filteredTenants])

  const toggleSelectedTenant = tenantId => {
    setSelectedTenants(selectedTenants =>
      selectedTenants.includes(tenantId)
        ? selectedTenants.filter(id => id !== tenantId)
        : [...selectedTenants, tenantId]
    )
  }

  const isTenantDisabled = tenantId => disabledTenants.includes(tenantId)

  const buildSelectBaselineTable = () => (
    <>
      <div className='flex flex-wrap items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700  mr-4'
          type='text'
          placeholder='Search'
          value={baselineSearch}
          onChange={e => setBaselineSearch(e.target.value)}
        />
        <div>
          <button
            type='button'
            className='btn navy-btn mt-4 mb-4'
            onClick={() => setBaselineSearch('')}
          >
            Clear
          </button>
        </div>
      </div>
      <div className='table-container'>
        <table role='table'>
          <tbody>
            <tr className='table-head'>
              <td>Tags</td>
              <td>Tenant Name</td>
              <td aria-label='Is Selected' />
            </tr>
            {filteredTenantBaselines.map(tenant => (
              <tr
                key={tenant.clientTenantId}
                onClick={() => setBaselineTenantId(tenant.clientTenantId)}
                className='tenant-row cursor-pointer'
              >
                {tenant.isSharedBaseline ? (
                  <td className='baseline-cell'>
                    <span className='backedup-tag'>Shared Baseline</span>
                  </td>
                ) : (
                  <td className='baseline-cell'>
                    <span className='baseline-tag'>Baseline</span>
                  </td>
                )}
                <td>{tenant.tenantFriendlyName}</td>
                <td className='check-cell'>
                  {baselineTenantId === tenant.clientTenantId && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      data-testid='tenant-checked-icon'
                      className='ml-2'
                    />
                  )}
                </td>
              </tr>
            ))}
            {filteredTenantBaselines.length === 0 ? (
              <tr>
                <td colSpan={2}>No customers found</td>
                <td aria-label='Check Cell' />
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  )

  const buildSelectTenantsTable = () => (
    <>
      <div className='flex flex-wrap items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mr-4'
          type='text'
          placeholder='Search'
          value={tenantSearch}
          onChange={e => setTenantSearch(e.target.value)}
        />
        <div>
          <button
            type='button'
            className='btn navy-btn mt-4 mb-4'
            onClick={() => setTenantSearch('')}
          >
            Clear
          </button>
        </div>
      </div>
      <div className='table-container'>
        <table role='table'>
          <tbody>
            <tr className='table-head'>
              <td>Tags</td>
              <td>Tenant Name</td>
              <td aria-label='Is Selected' />
            </tr>
            {filteredTenants.map(tenant => {
              const isDisabled = isTenantDisabled(tenant.clientTenantId)
              return (
                <tr
                  key={tenant.clientTenantId}
                  onClick={() =>
                    !isDisabled && toggleSelectedTenant(tenant.clientTenantId)
                  }
                  className={`tenant-row cursor-pointer ${isDisabled ? 'cursor-not-allowed' : ''}`}
                  style={{ color: isDisabled ? 'gray' : 'inherit' }}
                >
                  <td className='baseline-cell'>
                    {tenant.isBaseline && (
                      <span className='baseline-tag'>Baseline</span>
                    )}
                  </td>

                  <td>{tenant.tenantFriendlyName}</td>
                  <td className='check-cell'>
                    {selectedTenants.includes(tenant.clientTenantId) ? (
                      <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                    ) : null}
                  </td>
                </tr>
              )
            })}
            {filteredTenants.length === 0 ? (
              <tr>
                <td colSpan={2}>No customers found</td>
                <td aria-label='Check Cell' />
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  )

  return (
    <>
      <h4 className='mb-6 text-lg'>Add / Edit Group</h4>
      <button type='button' className='btn text-btn' onClick={onClose}>
        <FontAwesomeIcon icon={faChevronLeft} className='pr-2' />
        Back
      </button>
      <div className='w-full max-w-md mt-4'>
        <div className='flex flex-col space-y-2 mt-2'>
          <label className='font-semibold'>Name</label>
          <input
            type='text'
            value={name}
            onChange={event => setName(event.target.value)}
            className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
          />
        </div>
        <div className='flex flex-row flex-wrap mt-10'>
          <div className='mr-4'>
            <label className='font-semibold'>Alignment Threshold</label>
            <br />
            <input
              type='number'
              value={alignmentThreshold}
              onChange={event => setAlignmentThreshold(event.target.value)}
              className='w-[200px] border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
            />
          </div>
          <div>
            <label className='font-semibold'>Semi-Aligned Threshold</label>
            <br />
            <input
              type='number'
              value={semiAlignmentThreshold}
              onChange={event => setSemiAlignmentThreshold(event.target.value)}
              className='w-[200px] border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
            />
          </div>
        </div>
        <div className='mt-10'>
          <label className='font-semibold'>
            Tenant containing baseline properties
          </label>
          {buildSelectBaselineTable()}
        </div>
        <div className='mt-10'>
          <label className='font-semibold'>
            Tenants aligned to this baseline
          </label>
          {buildSelectTenantsTable()}
        </div>
        <button
          type='button'
          className='btn cyan-btn mt-10'
          disabled={loading || !saveEnabled || !validAlignmentThresholds}
          onClick={handleSave}
        >
          <FontAwesomeIcon icon={faSave} />
          &nbsp;&nbsp;{loading ? 'Saving' : 'Save Group'}
        </button>
      </div>
    </>
  )
}

export default BaselineEditor
