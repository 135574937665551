import { useEffect, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import Pagination from './Pagination'

import SearchBar from './SearchBar'
import useGetClients from '../api/useGetClients'
import SharedBaselinesSkeleton from './SharedBaselineGroupSkeleton'

const MembersSelect = () => {
  const {
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useFormContext()

  const { data: clients = [], error, isError, isLoading } = useGetClients()

  const [isAllSelected, setIsAllSelected] = useState(false)

  const searchMembers = useWatch({
    control,
    name: 'searchMembers',
    defaultValue: '',
  })

  const selectedClientId = useWatch({
    control,
    name: 'clientId',
  })

  const availableToAllClients = useWatch({
    control,
    name: 'availableToAllClients',
  })

  const selectedMembers = useWatch({
    control,
    name: 'members',
    defaultValue: [],
  })

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  useEffect(() => {
    setCurrentPage(1) // Reset to first page when search term changes
  }, [searchMembers])

  const filteredMembers = clients.filter(
    client =>
      client.clientId !== Number(selectedClientId) &&
      client.displayName.toLowerCase().includes(searchMembers.toLowerCase())
  )

  const paginatedMembers = filteredMembers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  const handleSelectMembers = memberId => event => {
    const newCheckedItems = [...selectedMembers]
    if (event.target.checked) {
      newCheckedItems.push(memberId)
    } else {
      const index = newCheckedItems.indexOf(memberId)
      if (index !== -1) {
        newCheckedItems.splice(index, 1)
      }
    }
    setValue('members', newCheckedItems)
  }

  const handleSelectAllChange = () => {
    if (isAllSelected) {
      setValue('members', [])
    } else {
      setValue(
        'members',
        filteredMembers.map(member => member.clientId)
      )
    }
    setIsAllSelected(!isAllSelected)
  }

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  if (isLoading) {
    return (
      <div className='p-4 border mt-2'>
        <SharedBaselinesSkeleton loadingTitle='Loading members...' />
      </div>
    )
  }

  if (isError) {
    return (
      <div className='p-4 border mt-2'>
        <div className='mt-3'>
          <p>{error.message}</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='font-semibold'>Select Members</div>

      <div className='flex border border-gray-200 dark:border-gray-700 p-3 mb-5'>
        <div className='flex items-center h-5'>
          <Controller
            control={control}
            name='availableToAllClients'
            defaultValue={false}
            render={() => (
              <input
                type='checkbox'
                id='availableToAllClients'
                value={availableToAllClients}
                checked={availableToAllClients}
                onChange={event => {
                  const isChecked = event.target.checked
                  setValue('availableToAllClients', isChecked)
                  if (isChecked) {
                    setValue('members', [])
                    setValue('inforcerManaged', isChecked)
                  } else {
                    resetField('inforcerManaged')
                    resetField('members')
                  }
                }}
              />
            )}
          />
        </div>
        <div className='ms-2'>
          <label htmlFor='availableToAllClients' className='dark:text-gray-300'>
            Assign to all members
          </label>
        </div>
      </div>

      <>
        <p className='font-normal'>or select individual members</p>

        <div
          className={`p-4 border mt-2 ${
            errors.members
              ? 'border-red-500'
              : 'focus:ring-blue-500 focus:border-blue-500'
          }`}
        >
          <SearchBar
            fieldName='searchMembers'
            type='checkbox'
            isAllSelected={isAllSelected}
            toggleSelectAll={handleSelectAllChange}
            disabled={availableToAllClients}
          />

          <div className='border border-gray-200 dark:border-gray-700 p-3 pb-10 mb-5'>
            {paginatedMembers
              .filter(client => client.clientId !== Number(selectedClientId))
              .map(member => (
                <div key={member.clientId} className='flex items-center mb-2'>
                  <Controller
                    control={control}
                    name='members'
                    rules={{ disabled: availableToAllClients }}
                    render={() => (
                      <input
                        type='checkbox'
                        id={`member-${member.clientId}`}
                        value={member.clientId}
                        className='mr-2'
                        checked={selectedMembers.includes(member.clientId)}
                        onChange={handleSelectMembers(member.clientId)}
                        disabled={availableToAllClients}
                      />
                    )}
                  />

                  <label
                    className='font-normal'
                    htmlFor={`member-${member.clientId}`}
                  >
                    {member.displayName}
                  </label>
                </div>
              ))}
          </div>

          <Pagination
            totalItems={filteredMembers.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            disabled={availableToAllClients}
          />
        </div>
      </>
    </>
  )
}

export default MembersSelect
