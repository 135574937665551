import { useEffect, useState } from 'react'
import Axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useFeatureFlag } from 'configcat-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useIntercom } from 'react-use-intercom'
import { ThemeProvider } from '@mui/material/styles'
import { SkeletonTheme } from 'react-loading-skeleton'

import { MuiTheme } from './utils/SetAppTheme'
import Header from './navigation/Header'
import DesktopSidebar from './navigation/DesktopSidebar'
import MobileSidebar from './navigation/MobileSidebar'
import GlobalContext from './contexts/GlobalContext'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import Backup from './journeys/Backup'
import Restore from './journeys/Restore/Restore'
import Deploy from './journeys/Deploy/Deploy'
import Reporting from './journeys/Reporting'
import Login from './journeys/Login'
import Settings from './journeys/Settings'
import TenantManagement from './journeys/TenantManagement'
import Onboarding from './journeys/Onboarding'
import UpdatePassword from './journeys/UpdatePassword'
import ForcePasswordRedirect from './journeys/ForcePasswordRedirect'

import EditTenantPage from './components/EditTenantPage'
import TenantRefresh from './journeys/TenantRefresh/TenantRefresh'

import TenantExplorer from './journeys/TenantExplorer'
import TenantAlignment from './journeys/TenantAlignment/TenantAlignment'
import SharedBaselines from './journeys/SharedBaselines/SharedBaselines'

import ForgotPassword from './journeys/ForgotPassword'
import ValidatePassReset from './journeys/ValidatePassReset'
import ResetForgottenPass from './journeys/ResetForgottenPass'

import AdminPortal from './admin/journeys/AdminPortal'
import AddUser from './admin/journeys/AddUser'
import AddOBCode from './admin/journeys/AddOBCode'
import RemoveOBCode from './admin/journeys/RemoveOBCode'
import AllClients from './admin/journeys/AllClients'
import AllTenants from './admin/journeys/AllTenants'
import ResetUserPass from './admin/journeys/ResetUserPass'
import RemoveUser from './admin/journeys/RemoveUser'
import NewClientOBCode from './admin/journeys/NewClientNewOBCode'
import PauseClient from './admin/journeys/PauseClient'

import Page404 from './components/Page404/Page404'
import HomeDashboard from './components/HomeDashboard/HomeDashboard'
import PendingChangesProvider from './contexts/PendingChangesStateContext'
import TenantRefreshStateProvider from './contexts/TenantRefreshStateContext'
import SharedBaselinesManage from './admin/journeys/SharedBaselinesManage'
import ManageSubscribers from './components/SharedBaselines/components/ManageSubscribers'
import NotificationsProvider from './contexts/NotificationsContext'

Axios.defaults.withCredentials = true

// Create a client
const queryClient = new QueryClient()

const App = () => {
  const [loadApp, setLoadApp] = useState(false)
  const [loadingState, setLoadingState] = useState(false)
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const [username, setUsername] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [forcePasswordChange, setForcePasswordChange] = useState(false)
  const [validatePassErr, setValidatePassErr] = useState('')

  const { value: enableSharedBaselines } = useFeatureFlag(
    'sharedBaselines',
    false
  )

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const { boot, shutdown } = useIntercom()

  const handleLogout = async () => {
    try {
      await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/logout`,
        {},
        { withCredentials: true }
      )
      shutdown()
      navigate('/login')
      localStorage.removeItem('isSalesAdmin')
      localStorage.removeItem('isClientAdmin')
      localStorage.removeItem('username')
      sessionStorage.clear()
      setLoadApp(false)
    } catch (error) {
      if (error.response.status === 500) {
        toast.info('Sorry, there was an error logging out. Please try again.')
      }
    }
  }

  const handleCloseNotifications = () => {
    if (!notificationsOpen) return
    setNotificationsOpen(false)
  }

  useEffect(() => {
    const persistLogin = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/login`
        )
        // if you refresh while you're required to reset pw
        if (
          response.data.loggedIn === true &&
          response.data.forcePasswordChange === true
        ) {
          setForcePasswordChange(true)
        }
        // if you refresh while you're logged in
        if (
          response.data.loggedIn === true &&
          response.data.forcePasswordChange === undefined
        ) {
          setLoadApp(true)
          // Boot Intercom with cookies
          boot()
        }
      } catch (error) {
        toast.error('An error occurred. Please try logging in again.')
      } finally {
        setIsLoading(false)
      }
    }

    persistLogin()
  }, [boot])

  // Force change password enforcement
  useEffect(() => {
    const needsPasswordChange = localStorage.getItem('forcePasswordChange')
    if (needsPasswordChange) {
      setForcePasswordChange(true)
    }
  }, [])

  useEffect(() => {
    const getSSOSession = async () => {
      try {
        const session = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/sso/session`,
          {}
        )
        const {
          clientAdmin,
          clientId,
          customerName,
          createDate,
          displayName,
          emailHash,
          salesAdmin,
          username: sessionUsername,
        } = session.data

        localStorage.setItem('isSalesAdmin', salesAdmin)
        localStorage.setItem('isClientAdmin', clientAdmin)
        localStorage.setItem('isSSOUser', true)
        setUsername(sessionUsername)

        boot({
          appId: process.env.REACT_APP_INTERCOM_APP_ID,
          apiBase: 'https://api-iam.intercom.io',
          name: displayName,
          email: sessionUsername,
          createdAt: createDate,
          userHash: emailHash,
          company: {
            companyId: clientId.toString(),
            name: customerName,
          },
        })
      } catch {
        setLoadApp(false) // ?
        setIsLoading(false)
      }
    }
    const ssoInit = searchParams.get('ssoInit')
    if (ssoInit === 'true') {
      setLoadApp(true)
      getSSOSession()
      setIsLoading(false)
    }
  }, [searchParams, boot])

  // forgot password validate step
  const validatePassReq = async token => {
    setLoadingState(true)
    setValidatePassErr('')
    const requestBody = {
      Id: token,
    }

    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/validatePassResetFE`,
        requestBody
      )
      localStorage.setItem('userId', response.data.userId)
      localStorage.setItem('resetToken', token)
      navigate('/reset-password ')
    } catch (error) {
      setValidatePassErr(
        'An error has occurred. Please try to reset your password again.'
      )
    } finally {
      setLoadingState(false)
    }
  }

  const isSalesAdmin = localStorage.getItem('isSalesAdmin')

  // Opens Intercom if user clicks 'Raise a support ticket' from ExceptionPage
  const { show } = useIntercom()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get('openIntercom') === 'true') {
      setTimeout(() => {
        show()
      }, 2000)
    }
  }, [location.search, show])

  return (
    <>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme duration={1}>
          <NotificationsProvider isActive={loadApp}>
            <GlobalContext.Provider
              value={{
                username,
                setUsername,
                setLoadApp,
                loadingState,
                setLoadingState,
                forcePasswordChange,
                setForcePasswordChange,
              }}
            >
              <ThemeProvider theme={MuiTheme}>
                {isLoading && (
                  <div id='loading-spinner' className='app-spinner'>
                    <div className='loading' />
                  </div>
                )}
                {!isLoading && forcePasswordChange && (
                  <Routes>
                    <Route
                      path='/update-password'
                      element={<UpdatePassword />}
                    />
                    <Route path='*' element={<ForcePasswordRedirect />} />
                  </Routes>
                )}
                {loadApp ? (
                  <div className='app' onClick={handleCloseNotifications}>
                    <DesktopSidebar />
                    <MobileSidebar />
                    <div className='body-container'>
                      <Header handleLogout={handleLogout} />
                      <main className='dark:bg-slate-600'>
                        <Routes>
                          <Route path='/' element={<HomeDashboard />} />
                          <Route path='/backup' element={<Backup />} />
                          <Route path='/restore' element={<Restore />} />
                          <Route path='/deploy' element={<Deploy />} />
                          <Route path='/reporting' element={<Reporting />} />
                          <Route path='/settings' element={<Settings />} />
                          <Route
                            path='/tenant-management'
                            element={<TenantManagement />}
                          />
                          <Route
                            path='/edit-tenant/:clientTenantId'
                            element={<EditTenantPage />}
                          />
                          <Route
                            path='/policy-explorer'
                            element={<TenantExplorer />}
                          />
                          <Route path='/onboarding' element={<Onboarding />} />
                          <Route
                            path='/tenant-refresh'
                            element={<TenantRefresh />}
                          />
                          <Route
                            path='/tenant-alignment'
                            element={
                              <PendingChangesProvider>
                                <TenantRefreshStateProvider>
                                  <TenantAlignment />
                                </TenantRefreshStateProvider>
                              </PendingChangesProvider>
                            }
                          />
                          {enableSharedBaselines && (
                            <Route
                              path='/shared-baselines'
                              element={<SharedBaselines />}
                            />
                          )}
                          <Route path='*' element={<Page404 />} />

                          {isSalesAdmin === 'true' && (
                            <>
                              <Route
                                path='/admin-portal/new-client-new-onboarding-code'
                                element={<NewClientOBCode />}
                              />
                              <Route
                                path='/admin-portal/all-clients'
                                element={<AllClients />}
                              />
                              <Route
                                path='/admin-portal/all-tenants'
                                element={<AllTenants />}
                              />
                              <Route
                                path='/admin-portal/add-onboarding-code'
                                element={<AddOBCode />}
                              />
                              <Route
                                path='/admin-portal/remove-onboarding-code'
                                element={<RemoveOBCode />}
                              />
                              <Route
                                path='/admin-portal/add-user'
                                element={<AddUser />}
                              />
                              <Route
                                path='/admin-portal/reset-user-pass'
                                element={<ResetUserPass />}
                              />
                              <Route
                                path='/admin-portal/remove-user'
                                element={<RemoveUser />}
                              />
                              <Route
                                path='/admin-portal/pause-client'
                                element={<PauseClient />}
                              />
                              <Route
                                path='/admin-portal'
                                element={<AdminPortal />}
                              />

                              {enableSharedBaselines && (
                                <>
                                  <Route
                                    path='/admin-portal/shared-baselines-manage'
                                    element={<SharedBaselinesManage />}
                                  />
                                  <Route
                                    path='/manage-subscribers/:baselineGroupId'
                                    element={<ManageSubscribers />}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </Routes>
                      </main>
                    </div>
                  </div>
                ) : (
                  <Routes>
                    <Route path='/login' element={<Login />} />
                    <Route
                      path='/forgot-password'
                      element={<ForgotPassword />}
                    />
                    <Route
                      path='/passwordResetRequest/:token'
                      element={
                        <ValidatePassReset
                          validatePassReq={validatePassReq}
                          validatePassErr={validatePassErr}
                          loadingState={loadingState}
                        />
                      }
                    />
                    <Route
                      path='/reset-password'
                      element={<ResetForgottenPass />}
                    />
                    <Route path='*' element={<Login />} />
                  </Routes>
                )}
              </ThemeProvider>
            </GlobalContext.Provider>
          </NotificationsProvider>
        </SkeletonTheme>
      </QueryClientProvider>
    </>
  )
}

export default App
